import React, { useEffect, useRef, useState } from "react";
import { Image, Row, Col, Form, Button, Tab, Nav } from "react-bootstrap";
import Select, { components } from "react-select";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import "@djthoms/pretty-checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  countryListStart,
  currencyConversionStart,
} from "../../store/slices/BeneficiariesSlice";
import { useTranslation } from "react-multi-lang";
import * as Yup from "yup";
import { ButtonLoader } from "../Helper/Loader";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";

const CurrencyConversionIndex = (props) => {

  const formRef = useRef();
  const dispatch = useDispatch();
  const t = useTranslation("currency_conversion");

  const profile = useSelector((state) => state.admin.profile);
  const countriesLists = useSelector((state) => state.beneficiaries.countryList);
  const currencyConversion = useSelector((state) => state.beneficiaries.currencyConversion);

  const [recipientType, setRecipientType] = useState(1);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [skipRender, setSkipRender] = useState(true);
  const [exchangeData, setExchangeData] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [selectedRecivingCurrency, setSelectedRecivingCurrency] = useState(null);
  const [selectedRecivingCountry, setSelectedRecivingCountry] = useState(null);
  const [selectedFromCountry, setSelectedFromCountry] = useState({
    label: `${"USA"} (${"USD"})`,
    value: "USD",
    flag: "https://cms-efibank-staging.rare-able.com/images/countries/us.png",
  });

  const validationSchema = Yup.object().shape({
    receiving_currency: Yup.string().required(t("required")),
    recipient_country: Yup.string().required(t("required")),
    recipient_type: Yup.string().required(t("required")),
    amount: Yup.number().required(t("required"))
      .min(1, t("amount.at_least"))
      .max(100000, t("amount.max")),
  });

  useEffect(() => {
    dispatch(
      countryListStart({
        recipient_type: recipientType,
      })
    );
  }, [recipientType]);

  useEffect(() => {
    if (
      !skipRender &&
      !currencyConversion.loading &&
      Object.keys(currencyConversion.data).length > 0
    ) {
      setExchangeData(currencyConversion.data);
    }
    setSkipRender(false);
  }, [currencyConversion]);

  useEffect(() => {
    if (
      !skipRender &&
      !countriesLists.loading &&
      Object.keys(countriesLists.data).length > 0
    ) {
      setCountryCodeList(
        countriesLists.data.receipient_type_countries.map((data) => ({
          label: `${data.currency} - ${data.country_name}`,
          value: `${data.currency}${data.country_code}`,
          flag: data.flag,
        })))


    }
    setSkipRender(false);
  }, [countriesLists]);

  useEffect(() => {
    if (countryCodeList.length > 0) {
      let data =
        countryCodeList.find(
          (item) =>
            item.value.slice(3) == countriesLists.data.quote_default_to_country
        ) || countryListStart[0];
      formRef.current?.setFieldValue(
        "receiving_currency",
        data.value.slice(0, 3)
      );
      formRef.current?.setFieldValue("recipient_country", data.value.slice(3));
      setSelectedRecivingCountry(data);
      setSelectedRecivingCurrency(data);
      data &&
        handleSubmit({
          ...formRef.current?.values,
          receiving_currency: data.value.slice(0, 3),
          recipient_country: data.value.slice(3),
        });
    }
  }, [countryCodeList]);

  const handleSubmit = (values) => {
    setExchangeData(false);
    const { ...rest } = values;
    dispatch(currencyConversionStart({ ...rest, quote_type: "FORWARD" }));
  };

  const SingleValue = ({ data, ...props }) => (
    <components.SingleValue {...props}>
      <img
        src={data.flag}
        alt=""
        style={{
          width: "20px",
          height: "20px",
          marginRight: "10px",
        }}
      />
      {data.label}
    </components.SingleValue>
  );

  const Option = ({ data, ...props }) => (
    <components.Option {...props}>
      <img
        src={data.flag}
        alt=""
        style={{
          width: "20px",
          height: "20px",
          marginRight: "10px",
        }}
      />
      {data.label}
    </components.Option>
  );

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "transparent!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.95em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#fff",
      fontSize: "0.95em",
      fontWeight: "500",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.95em",
      fontWeight: "500",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#1b3d86!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#dde1e6",
      },
    }),
    // option: (provided, state) => ({
    //   ...provided,
    //   backgroundColor: state.isFocused || state.isSelected ? "#1b3d86" : "#fff",
    //   color: state.isFocused || state.isSelected ? "#fff" : "#000",
    //   ":hover": {
    //     backgroundColor: "#1b3d86",
    //     color: "#fff",
    //   },
    // }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#1b3d86"
        : state.isFocused
          ? "#f0f0f0"
          : "transparent",
      color: state.isSelected ? "#fff" : state.isFocused ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#f0f0f0",
        color: "#000",
      },
    }),
  };

  useEffect(() => {
    if (
      !skipRender &&
      !currencyConversion.loading &&
      Object.keys(currencyConversion.data).length > 0
    ) {
      formRef.current?.setFieldValue("amount", currencyConversion.data.converted_amount.amount);
    }
    setSkipRender(false);
  }, [currencyConversion]);

  return (
    <>
      {" "}
      <div className="full-body-card-wrapped">
        <div className="currency-conversion-body">
          <Tab.Container id="left-tabs-example" defaultActiveKey="calculator">
            {/* <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="calculator">Calculator</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="history">History</Nav.Link>
              </Nav.Item>
            </Nav> */}
            <Tab.Content>
              <Tab.Pane eventKey="calculator">
                <Row className="justify-content-center">
                  <Col lg={7}>
                    <div className="single-transaction-frame">
                      <div className="single-transaction-titles">
                        <h4>{t('title')}</h4>
                        <p>
                          {t('content')}
                        </p>
                      </div>
                      <div className="single-transaction-content">
                        {countriesLists.loading ? (
                          <>
                            <Skeleton className="mb-3" width="50%" height={60} />
                            {[Array(2)].map((item) => (
                              <Skeleton count={2} height={80} className="mb-3" />
                            ))}
                            <br />
                            <Skeleton className="mb-3" height={100} />
                          </>
                        ) : Object.keys(countriesLists.data).length > 0 ? (
                          <Formik
                            initialValues={{
                              user_type: profile.data.user_type,
                              recipient_type: recipientType,
                              receiving_currency: "",
                              recipient_country: "",
                              amount: countriesLists.data.quote_default_from_amount,
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                              handleSubmit(values);
                            }}
                            innerRef={formRef}
                          >
                            {({
                              errors,
                              touched,
                              values,
                              setFieldValue,
                              setValues,
                              isValid
                            }) => (
                              <FORM>
                                {values.user_type == 2 ? (
                                  <div className="single-transaction-box-field mb-3">
                                    <div className="beneficiary-from-heading">
                                      <h3>{t("beneficiary_type")}</h3>
                                    </div>
                                    <Col md={12} className="mt-3">
                                      <Form.Group className="mb-3">
                                        <Radio
                                          name="radio"
                                          onClick={(e) => {
                                            setFieldValue(
                                              "recipient_type",
                                              e.target.value
                                            );
                                            setRecipientType(1);
                                            setFieldValue(
                                              "receiving_currency",
                                              ""
                                            );
                                            setFieldValue(
                                              "recipient_country",
                                              ""
                                            );
                                            setSelectedRecivingCountry(null);
                                            setSelectedRecivingCurrency(null);
                                          }}
                                          value="1"
                                          checked={values.recipient_type == 1}
                                          disabled={
                                            currencyConversion.buttonDisable
                                          }
                                        >
                                          {t("individual")}
                                        </Radio>
                                        <Radio
                                          name="radio"
                                          onClick={(e) => {
                                            setFieldValue(
                                              "recipient_type",
                                              e.target.value
                                            );
                                            setRecipientType(2);
                                            setFieldValue(
                                              "receiving_currency",
                                              ""
                                            );
                                            setFieldValue(
                                              "recipient_country",
                                              ""
                                            );
                                            setSelectedRecivingCountry(null);
                                            setSelectedRecivingCurrency(null);
                                          }}
                                          value="2"
                                          checked={values.recipient_type == 2}
                                          disabled={
                                            currencyConversion.buttonDisable
                                          }
                                        >
                                          {t("business")}
                                        </Radio>
                                        <ErrorMessage
                                          component={"div"}
                                          name="recipient_type"
                                          className="errorMsg"
                                        />
                                      </Form.Group>
                                    </Col>
                                  </div>
                                ) : null}
                                <div className="single-transaction-box-field mb-3">
                                  <Form.Label className="default-form-label">
                                    {t('from')}
                                  </Form.Label>
                                  <div className="single-transaction-box">
                                    <div className="single-transaction-fields">
                                      <Form.Group>
                                        <Field
                                          type="number"
                                          className="form-control"
                                          name="amount"
                                          placeholder={t("amount.placeholder")}
                                          disabled={currencyConversion.buttonDisable}
                                          min={1}
                                          onBlur={(e) => isValid ? handleSubmit(values) : getErrorNotificationMessage("Amount must be between 1 to 100000")}
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="single-transaction-country">
                                      <Select
                                        options={[selectedFromCountry]}
                                        styles={customStyles}
                                        value={selectedFromCountry}
                                        classNamePrefix="react-select"
                                        placeholder="Currency"
                                        components={{ SingleValue, Option }}
                                        isSearchable={false}
                                      />
                                    </div>
                                  </div>
                                  <ErrorMessage
                                    component={"div"}
                                    name="amount"
                                    className="text-danger"
                                  />
                                </div>
                                <div className="single-transaction-box-field">
                                  <Form.Label className="default-form-label">
                                    {t('to')}
                                  </Form.Label>
                                  <div className="single-transaction-box">
                                    <div className="single-transaction-fields">
                                      <Form.Group>
                                        <Field
                                          type="text"
                                          className="form-control no-caret-input"
                                          name="exchange_amount"
                                          placeholder={"Exchange Amount"}
                                          value={
                                            exchangeData?.recipient_amount
                                              ?.amount
                                              ? exchangeData.recipient_amount
                                                .amount
                                              : 0
                                          }
                                        // disabled
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="single-transaction-country">
                                      <Select
                                        options={countryCodeList}
                                        styles={customStyles}
                                        name="receiving_currency"
                                        classNamePrefix="react-select"
                                        placeholder="Currency"
                                        value={selectedRecivingCurrency}
                                        onChange={(data) => {
                                          setFieldValue("receiving_currency", data.value.slice(0, 3));
                                          setFieldValue("recipient_country", data.value.slice(3));
                                          setSelectedRecivingCountry(data);
                                          setSelectedRecivingCurrency(data);
                                          // isValid ? : getErrorNotificationMessage("Invalid");
                                          handleSubmit({
                                            ...values,
                                            receiving_currency: data.value.slice(0, 3),
                                            recipient_country: data.value.slice(3),
                                          })
                                        }}
                                        isDisabled={
                                          currencyConversion.buttonDisable
                                        }
                                        components={{ SingleValue, Option }}
                                        isSearchable={false}
                                      />
                                    </div>
                                  </div>
                                  {!values.receiving_currency ? (
                                    <ErrorMessage
                                      component={"div"}
                                      name="receiving_currency"
                                      className="text-danger"
                                    />
                                  ) : null}
                                </div>
                                <div className="single-space"></div>
                                <div className="single-transaction-info-box margin-btm-sm">
                                  {exchangeData &&
                                    !currencyConversion.buttonDisable ? (
                                    <React.Fragment>
                                      <div className="single-transaction-info-card">
                                        <h4>{t("exchange_rate")}</h4>
                                        <h5>
                                          {exchangeData.converting_currency}
                                          <span>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              enableBackground="new 0 0 512 512"
                                              viewBox="0 0 64 64"
                                            >
                                              <g fill="#1b3d86">
                                                <path
                                                  d="M53.121 13.594L36.08 4.25a2.027 2.027 0 00-2.977 1.773v2.9c-13.996.018-22.815 9.592-23.516 17.746l.088 2.693c5.963-6.725 15.16-9.554 22.605-9.216v3.8a2.027 2.027 0 002.81 1.851l17.796-7.639a2.559 2.559 0 00.235-4.564z"
                                                  data-original="#000000"
                                                ></path>
                                                <path
                                                  d="M12.104 29.877l-1.841 1.944a17.582 17.582 0 004.143 6.581.988.988 0 001.665-.725c-.226-4.1.79-10.006 1.86-12.35q-.363.195-.737.416a23.582 23.582 0 00-5.09 4.134zm-1.225 20.529l17.041 9.343a2.027 2.027 0 002.977-1.772v-2.9c13.996-.018 22.815-9.592 23.516-17.746l-.088-2.693c-5.963 6.725-15.16 9.554-22.605 9.216v-3.8a2.027 2.027 0 00-2.81-1.851l-17.796 7.639a2.559 2.559 0 00-.235 4.564z"
                                                  data-original="#000000"
                                                ></path>
                                                <path
                                                  d="M51.896 34.123l1.841-1.944a17.582 17.582 0 00-4.143-6.581.988.988 0 00-1.665.725c.226 4.1-.79 10.006-1.86 12.35q.363-.195.737-.416a23.582 23.582 0 005.09-4.134z"
                                                  data-original="#000000"
                                                ></path>
                                              </g>
                                            </svg>
                                          </span>{" "}
                                          {exchangeData.receiving_currency}
                                        </h5>
                                      </div>
                                      <div className="single-transaction-info-card">
                                        <h4>{t("fees")}</h4>
                                        <h5>
                                          $
                                          {(exchangeData.charged_amount.amount - exchangeData.converted_amount.amount).toFixed(2)}
                                        </h5>
                                      </div>
                                      <div className="single-transaction-info-card">
                                        <h4>{t("total_payable")}</h4>
                                        <h5>
                                          ${exchangeData.charged_amount.amount}
                                        </h5>
                                      </div>
                                    </React.Fragment>
                                  ) : null}
                                  {/* <div className="single-transaction-info-card text-center">
                                    <Checkbox
                                      icon={
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/img/tick-mark.svg"
                                          }
                                          className="tick-mark-checkbox"
                                        />
                                      }
                                      checked={agreement}
                                      onChange={() => setAgreement(!agreement)}
                                    >
                                      Accept Terms of Use
                                    </Checkbox>
                                  </div> */}
                                </div>
                                <div className="single-transaction-box-action">
                                  <Button
                                    type="submit"
                                    className="efi-primary-btn w-100"
                                    disabled={
                                      currencyConversion.buttonDisable ||
                                      !agreement ||
                                      (currencyConversion?.data
                                        ?.converted_amount?.amount ==
                                        values.amount &&
                                        currencyConversion?.data
                                          ?.recipient_amount?.currency ==
                                        values.receiving_currency)
                                    }
                                  >
                                    {currencyConversion.buttonDisable ? (
                                      <ButtonLoader />
                                    ) : (
                                      t('convert')
                                    )}
                                  </Button>
                                </div>
                              </FORM>
                            )}
                          </Formik>
                        ) : <NoDataFound />}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              {/* <Tab.Pane eventKey="history">
                <h2>History</h2>
              </Tab.Pane> */}
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default CurrencyConversionIndex;
