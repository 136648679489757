import React, { useState } from "react";
import { toggleSidebar } from "../../../store/slices/CommonSlice";
import { Image, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import configuration from "react-global-configuration";

const Sidebar = (props) => {

  const location = useLocation();
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.admin.profile);

  return (
    <>
      <div className={`admin-ui-sidebar`}>
        {/* <div className="admin-collapse-btn">
          <Button className="collapse-close-btn"  onMouseOver={() => dispatch(toggleSidebar())}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              data-name="Layer 1"
              viewBox="0 0 512 512"
            >
              <path d="M121.373 457.373L322.745 256 121.373 54.627a32 32 0 0145.254-45.254l224 224a32 32 0 010 45.254l-224 224a32 32 0 01-45.254-45.254z"></path>
            </svg>
          </Button>
        </div> */}
        <div className="admin-ui-sidebar-collapse">
          <div className="admin-ui-collapse-logo-sec">
            <Link className="" to="/">
              <Image
                className="admin-ui-logo"
                src={configuration.get("configData.site_icon") || window.location.origin + "/img/fav-icon.png"}
              />
            </Link>
          </div>
          <div className="admin-ui-collapse-body navigate-icons">
            <ul className="admin-ui-sidebar-list list-unstyled link-scroll-icon">
              {/* <li>
                <Link to="/dashboard"
                  className={`${location.pathname.includes(
                    "dashboard"
                  ) && "active"
                    }`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#656565"
                      d="M22 8.52V3.98C22 2.57 21.36 2 19.77 2h-4.04c-1.59 0-2.23.57-2.23 1.98v4.53c0 1.42.64 1.98 2.23 1.98h4.04c1.59.01 2.23-.56 2.23-1.97z"
                      opacity="0.4"
                    ></path>
                    <path
                      fill="#656565"
                      d="M22 19.77v-4.04c0-1.59-.64-2.23-2.23-2.23h-4.04c-1.59 0-2.23.64-2.23 2.23v4.04c0 1.59.64 2.23 2.23 2.23h4.04c1.59 0 2.23-.64 2.23-2.23zM10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98v4.53c0 1.42.64 1.98 2.23 1.98h4.04c1.59.01 2.23-.56 2.23-1.97z"
                    ></path>
                    <path
                      fill="#656565"
                      d="M10.5 19.77v-4.04c0-1.59-.64-2.23-2.23-2.23H4.23c-1.59 0-2.23.64-2.23 2.23v4.04C2 21.36 2.64 22 4.23 22h4.04c1.59 0 2.23-.64 2.23-2.23z"
                      opacity="0.4"
                    ></path>
                  </svg>
                </Link>
              </li> */}
              <li>
                <Link
                  to="/account"
                  className={`my-first-step ${["/account", "/onboarding"].includes(location.pathname) && "active"
                    }`}
                >
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#f1f1f1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-building-bank"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 21l18 0" /><path d="M3 10l18 0" /><path d="M5 6l7 -3l7 3" /><path d="M4 10l0 11" /><path d="M20 10l0 11" /><path d="M8 14l0 3" /><path d="M12 14l0 3" /><path d="M16 14l0 3" /></svg> */}
                  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" fill="#fff" viewBox="0 0 24 24" width="24" height="24">
                    <path d="M23.9,11.437A12,12,0,0,0,0,13a11.878,11.878,0,0,0,3.759,8.712A4.84,4.84,0,0,0,7.113,23H16.88a4.994,4.994,0,0,0,3.509-1.429A11.944,11.944,0,0,0,23.9,11.437Zm-4.909,8.7A3,3,0,0,1,16.88,21H7.113a2.862,2.862,0,0,1-1.981-.741A9.9,9.9,0,0,1,2,13,10.014,10.014,0,0,1,5.338,5.543,9.881,9.881,0,0,1,11.986,3a10.553,10.553,0,0,1,1.174.066,9.994,9.994,0,0,1,5.831,17.076ZM7.807,17.285a1,1,0,0,1-1.4,1.43A8,8,0,0,1,12,5a8.072,8.072,0,0,1,1.143.081,1,1,0,0,1,.847,1.133.989.989,0,0,1-1.133.848,6,6,0,0,0-5.05,10.223Zm12.112-5.428A8.072,8.072,0,0,1,20,13a7.931,7.931,0,0,1-2.408,5.716,1,1,0,0,1-1.4-1.432,5.98,5.98,0,0,0,1.744-5.141,1,1,0,0,1,1.981-.286Zm-5.993.631a2.033,2.033,0,1,1-1.414-1.414l3.781-3.781a1,1,0,1,1,1.414,1.414Z" />
                  </svg>
                </Link>
              </li>
              {/* {profile.data.user_type == 2 ? ( */}
              <li>
                <Link
                  to="/virtual-account"
                  className={`my-second-step ${location.pathname.includes("virtual-account") && "active"
                    }`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24" fill="#fff">
                    <path d="M19,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,19,0ZM7,22V21a5,5,0,0,1,10,0v1Zm15-3a3,3,0,0,1-3,3V21A7,7,0,0,0,5,21v1a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2H19a3,3,0,0,1,3,3Z" />
                    <path d="M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,10Z" />
                  </svg>
                </Link>
              </li>
              <li>
                <Link
                  to="/currency-conversion"
                  className={`my-fourth-step ${location.pathname.includes("currency-conversion") && "active"
                    }`}
                >
                   <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="24" height="24" fill="#fff">
                  <path d="M0,11.5C0,6.813,3.813,3,8.5,3h10.5V1.337C19,.446,20.077,0,20.707,.63l3,3.163c.391,.391,.39,1.024,0,1.414l-3,3.163c-.63,.63-1.707,.184-1.707-.707v-1.663H8.5c-3.033,0-5.5,2.468-5.5,5.5,0,.828-.671,1.5-1.5,1.5s-1.5-.672-1.5-1.5Zm22.5-.5c-.829,0-1.5,.672-1.5,1.5,0,3.032-2.467,5.5-5.5,5.5H5v-1.663c0-.891-1.077-1.337-1.707-.707L.293,18.793c-.391,.391-.391,1.024,0,1.414l3,3.163c.63,.63,1.707,.184,1.707-.707v-1.663H15.5c4.687,0,8.5-3.813,8.5-8.5,0-.828-.671-1.5-1.5-1.5Z" />
                </svg>
                </Link>
              </li>
              <li>
                <Link
                  to="/send-money"
                  className={`my-third-step ${location.pathname.includes("send-money") && "active"
                    }`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24" fill="#fff">
                    <path d="M23.119.882a2.966,2.966,0,0,0-2.8-.8l-16,3.37a4.995,4.995,0,0,0-2.853,8.481L3.184,13.65a1,1,0,0,1,.293.708v3.168a2.965,2.965,0,0,0,.3,1.285l-.008.007.026.026A3,3,0,0,0,5.157,20.2l.026.026.007-.008a2.965,2.965,0,0,0,1.285.3H9.643a1,1,0,0,1,.707.292l1.717,1.717A4.963,4.963,0,0,0,15.587,24a5.049,5.049,0,0,0,1.605-.264,4.933,4.933,0,0,0,3.344-3.986L23.911,3.715A2.975,2.975,0,0,0,23.119.882ZM4.6,12.238,2.881,10.521a2.94,2.94,0,0,1-.722-3.074,2.978,2.978,0,0,1,2.5-2.026L20.5,2.086,5.475,17.113V14.358A2.978,2.978,0,0,0,4.6,12.238Zm13.971,7.17a3,3,0,0,1-5.089,1.712L11.762,19.4a2.978,2.978,0,0,0-2.119-.878H6.888L21.915,3.5Z" />
                  </svg>
                </Link>
              </li>
              {/* ) : null} */}
              {/* {profile.data.mastercard_service == 1 ? (
                <li>
                  <Link
                    to="/card-details"
                    className={`${
                      location.pathname.includes("card-details") && "active"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      fill="none"
                      viewBox="0 0 25 24"
                    >
                      <path
                        fill="#656565"
                        d="M22.03 7.54V9h-20V7.54c0-2.29 1.86-4.14 4.15-4.14h11.7c2.29 0 4.15 1.85 4.15 4.14z"
                      ></path>
                      <path
                        fill="#656565"
                        fillRule="evenodd"
                        d="M22.03 9v7.46c0 2.29-1.86 4.14-4.15 4.14H6.18c-2.29 0-4.15-1.85-4.15-4.14V9h20zm-14 8.25h-2c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h2c.41 0 .75.34.75.75s-.34.75-.75.75zm2.5 0h4c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-4c-.41 0-.75.34-.75.75s.34.75.75.75z"
                        clipRule="evenodd"
                        opacity="0.4"
                      ></path>
                    </svg>
                  </Link>
                </li>
              ) : null}
               */}
              {/* <li>
                <Link to="/user-access"
                  className={`${location.pathname.includes(
                    "user-access"
                  ) && "active"
                    }`}>
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-address-book"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M20 6v12a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2z" /><path d="M10 16h6" /><path d="M13 11m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M4 8h3" /><path d="M4 12h3" /><path d="M4 16h3" /></svg>
                </Link>
              </li> */}
              <li>
                <Link
                  to="/beneficiary-list"
                  className={`my-six-step ${["beneficiary-list", "create-beneficiary", "view-beneficiary"].includes(location.pathname?.split("/")[1]) && "active"
                    }`}
                >
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#f1f1f1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-invoice"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M19 12v7a1.78 1.78 0 0 1 -3.1 1.4a1.65 1.65 0 0 0 -2.6 0a1.65 1.65 0 0 1 -2.6 0a1.65 1.65 0 0 0 -2.6 0a1.78 1.78 0 0 1 -3.1 -1.4v-14a2 2 0 0 1 2 -2h7l5 5v4.25" /></svg> */}
                  <svg id="Layer_1" height="24" viewBox="0 0 24 24" width="24" fill="#fff" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1">
                    <path d="m7.5 13a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5zm7.5 17v-.5a7.5 7.5 0 0 0 -15 0v.5a1 1 0 0 0 2 0v-.5a5.5 5.5 0 0 1 11 0v.5a1 1 0 0 0 2 0zm9-5a7 7 0 0 0 -11.667-5.217 1 1 0 1 0 1.334 1.49 5 5 0 0 1 8.333 3.727 1 1 0 0 0 2 0zm-6.5-9a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5z" />
                  </svg>
                </Link>
              </li>
              <li>
                <Link
                  to="/beneficiary-transactions"
                  className={`my-seven-step ${["beneficiary-transactions", "beneficiary-transaction-view"].includes(location.pathname?.split("/")[1]) && "active"}`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="24" height="24" fill="#fff">
                    <path d="m23.268,17.768l-2.955,2.955c-.195.195-.451.293-.707.293s-.512-.098-.707-.293c-.391-.391-.391-1.023,0-1.414l2.309-2.309h-3.707c-1.379,0-2.5,1.121-2.5,2.5v3.5c0,.553-.447,1-1,1s-1-.447-1-1v-3.5c0-2.481,2.019-4.5,4.5-4.5h3.707l-2.293-2.293c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l2.939,2.939c.975.975.975,2.561,0,3.535ZM14,6c0,3.309-2.691,6-6,6s-6-2.691-6-6S4.691,0,8,0s6,2.691,6,6Zm-2,0c0-2.206-1.794-4-4-4s-4,1.794-4,4,1.794,4,4,4,4-1.794,4-4Zm-.752,8.283c-.732-.188-1.489-.283-2.248-.283C4.037,14,0,18.037,0,23c0,.553.447,1,1,1s1-.447,1-1c0-3.859,3.141-7,7-7,.592,0,1.181.074,1.752.221.529.139,1.079-.185,1.217-.721.137-.534-.186-1.079-.721-1.217Z" />
                  </svg>
                </Link>
              </li>
              <li>
                <Link
                  to="/recipients"
                  className={`${location.pathname.includes("recipients") && "active"
                    }`}
                >
                   <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="24" height="24" fill="#fff">
                    <path d="m5.5,7c1.93,0,3.5-1.57,3.5-3.5S7.43,0,5.5,0s-3.5,1.57-3.5,3.5,1.57,3.5,3.5,3.5Zm0-5c.827,0,1.5.673,1.5,1.5s-.673,1.5-1.5,1.5-1.5-.673-1.5-1.5.673-1.5,1.5-1.5Zm12,15c2.481,0,4.5-2.019,4.5-4.5s-2.019-4.5-4.5-4.5-4.5,2.019-4.5,4.5,2.019,4.5,4.5,4.5Zm0-7c1.379,0,2.5,1.121,2.5,2.5s-1.121,2.5-2.5,2.5-2.5-1.121-2.5-2.5,1.121-2.5,2.5-2.5Zm-9.067,8.607c.76.762.76,2.023-.013,2.798l-2.199,2.288c-.196.204-.458.307-.721.307-.25,0-.499-.093-.693-.279-.398-.383-.411-1.016-.028-1.414l1.256-1.307h-2.035c-2.206,0-4-1.794-4-4v-1c0-.553.448-1,1-1s1,.447,1,1v1c0,1.103.897,2,2,2h2.029l-1.25-1.307c-.383-.398-.371-1.031.028-1.414.398-.382,1.031-.371,1.414.027l2.212,2.301Zm14.85,5.352c-.095.028-.189.041-.282.041-.433,0-.831-.282-.959-.718-.568-1.933-2.436-3.282-4.541-3.282s-3.973,1.35-4.541,3.282c-.156.529-.71.836-1.242.677-.53-.155-.833-.711-.677-1.241.817-2.777,3.473-4.718,6.459-4.718s5.643,1.94,6.459,4.718c.156.53-.147,1.086-.677,1.241Zm-12.931-11.022c-.116.043-.234.063-.351.063-.405,0-.786-.247-.937-.649-.527-1.406-1.959-2.351-3.563-2.351s-3.037.944-3.563,2.351c-.194.519-.771.777-1.288.586-.517-.194-.779-.771-.585-1.287.818-2.183,3.003-3.649,5.437-3.649s4.618,1.467,5.437,3.649c.194.517-.068,1.093-.585,1.287Z" />
                  </svg>
                </Link>
              </li>
              <li>
                <Link
                  to="/transactions"
                  className={`${location.pathname == "/transactions" && "active"
                    }`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" id="arrow-circle-down" viewBox="0 0 24 24" width="24" height="24" fill="#fff">
                    <path d="M0,7A1,1,0,0,1,1,6H18V2.639a.792.792,0,0,1,1.35-.552l4.418,4.361a.773.773,0,0,1,0,1.1L19.35,11.913A.792.792,0,0,1,18,11.361V8H1A1,1,0,0,1,0,7Zm23,9H6V12.639a.792.792,0,0,0-1.35-.552L.232,16.448a.773.773,0,0,0,0,1.1L4.65,21.913A.792.792,0,0,0,6,21.361V18H23a1,1,0,0,0,0-2Z" />
                  </svg>
                </Link>
              </li>
              {profile.data.user_type == 2 ? (
                <React.Fragment>
                  <li>
                    <Link
                      to="/employee-list"
                      className={`${location.pathname.includes("employee-list") &&
                        "active"
                        }`}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="24" height="24" fill="#fff">
                        <path d="m13.5,12c0,2.206,1.794,4,4,4s4-1.794,4-4-1.794-4-4-4-4,1.794-4,4Zm4-2c1.103,0,2,.897,2,2s-.897,2-2,2-2-.897-2-2,.897-2,2-2Zm-5.5-2c2.206,0,4-1.794,4-4S14.206,0,12,0s-4,1.794-4,4,1.794,4,4,4Zm0-6c1.103,0,2,.897,2,2s-.897,2-2,2-2-.897-2-2,.897-2,2-2Zm-5.5,14c2.206,0,4-1.794,4-4s-1.794-4-4-4-4,1.794-4,4,1.794,4,4,4Zm0-6c1.103,0,2,.897,2,2s-.897,2-2,2-2-.897-2-2,.897-2,2-2Zm17.471,12.91c.05.55-.356,1.036-.906,1.086-.03.002-.061.004-.091.004-.512,0-.948-.391-.995-.91-.11-1.219-.737-2.355-1.721-3.118-.025-.02-.107-.011-.16.049l-1.841,2.133c-.189.22-.485.389-.757.347-.288,0-.562-.124-.752-.34l-1.865-2.126c-.051-.059-.133-.07-.16-.048-.986.774-1.591,1.876-1.701,3.104-.043.476-.413.82-.873.881-.029.005-.054.022-.084.025-.031.002-.061.004-.091.004-.01,0-.018-.005-.027-.005-.004,0-.007.002-.011.001-.011,0-.02-.008-.031-.009-.481-.034-.882-.402-.926-.897-.111-1.237-.722-2.344-1.72-3.118-.029-.021-.11-.011-.16.049l-1.841,2.133c-.19.221-.468.349-.757.347-.288,0-.562-.124-.752-.341l-1.865-2.126c-.052-.058-.134-.069-.16-.047-.987.774-1.592,1.876-1.702,3.104-.049.55-.528.948-1.086.906-.55-.05-.956-.536-.906-1.086.158-1.757,1.055-3.396,2.46-4.499.876-.686,2.148-.552,2.897.303l1.107,1.262,1.09-1.263c.745-.865,2.019-1.007,2.9-.322.608.471,1.115,1.047,1.516,1.685.396-.629.893-1.197,1.489-1.665.874-.685,2.148-.553,2.897.304l1.107,1.261,1.09-1.263c.745-.865,2.02-1.005,2.899-.323,1.421,1.102,2.327,2.749,2.487,4.519Z" />
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/employee-transactions"
                      className={`my-third-step ${location.pathname == "/employee-transactions" && "active"
                        }`}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="24" height="24" fill="#fff">
                        <path d="M21,11h-5c-1.654,0-3,1.346-3,3v7c0,1.654,1.346,3,3,3h5c1.654,0,3-1.346,3-3v-7c0-1.654-1.346-3-3-3Zm1,10c0,.551-.448,1-1,1h-5c-.552,0-1-.449-1-1v-7c0-.551,.448-1,1-1h5c.552,0,1,.449,1,1v7Zm-1-5.003c0,.552-.447,1-1,1h-3c-.553,0-1-.448-1-1s.447-1,1-1h3c.553,0,1,.448,1,1Zm0,3.003c0,.552-.447,1-1,1h-3c-.553,0-1-.448-1-1s.447-1,1-1h3c.553,0,1,.448,1,1Zm-12-7c3.309,0,6-2.691,6-6S12.309,0,9,0,3,2.691,3,6s2.691,6,6,6Zm0-10c2.206,0,4,1.794,4,4s-1.794,4-4,4-4-1.794-4-4,1.794-4,4-4Zm2,13c0,.552-.447,1-1,1h-1c-3.859,0-7,3.14-7,7,0,.552-.447,1-1,1s-1-.448-1-1c0-4.962,4.037-9,9-9h1c.553,0,1,.448,1,1Z" />
                      </svg>
                    </Link>
                  </li>
                </React.Fragment>
              ) : null}
              {profile.data.sender_status == 1 &&
                <li>
                  <Link
                    to="/sender-list"
                    className={`my-seven-step ${["sender-list", "view-sender-detail", "add-sender", "edit-sender"].includes(location.pathname?.split("/")[1]) && "active"
                      }`}
                  >
                     <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="24" height="24" fill="#fff">
                      <path d="m10.827,13.452c.436.366.968.552,1.496.552.375,0,.747-.094,1.08-.281l9.424-4.688c.734-.426,1.173-1.188,1.173-2.037,0-.849-.439-1.609-1.229-2.065L13.448.301c-.848-.476-1.875-.381-2.621.247-.744.625-1.015,1.622-.66,2.617l1.735,3.835-1.767,3.914c-.324.916-.052,1.913.691,2.538Zm7.87-6.822l-5.162-.871-1.514-3.339c-.059-.168.018-.278.093-.342.044-.037.105-.072.188-.072.058,0,.127.017.21.062l9.31,4.625c.153.089.177.229.177.306s-.023.218-.12.276l-9.412,4.68c-.157.089-.278.029-.354-.033-.075-.063-.152-.174-.125-.263l1.547-3.418,5.161-.871c.404-.081.404-.659,0-.74Zm-8.697,16.37c0,.553-.448,1-1,1h-2c-.552,0-1-.447-1-1s.448-1,1-1h2c.552,0,1,.447,1,1Zm14-3c0,2.206-1.794,4-4,4h-7c-.552,0-1-.447-1-1s.448-1,1-1h7c1.103,0,2-.897,2-2s-.897-2-2-2H6c-3.309,0-6-2.691-6-6s2.691-6,6-6h2c.552,0,1,.447,1,1s-.448,1-1,1h-2c-2.206,0-4,1.794-4,4s1.794,4,4,4h14c2.206,0,4,1.794,4,4Zm-20,3c0,.553-.448,1-1,1H1c-.552,0-1-.447-1-1s.448-1,1-1h2c.552,0,1,.447,1,1Z" />
                    </svg>
                  </Link>
                </li>}
              <li>
                <Link
                  to="/balance-statement"
                  className={`my-fifth-step ${location.pathname.includes("balance-statement") && "active"
                    }`}
                >
                 <svg id="Layer_1" height="24" viewBox="0 0 24 24" width="24" fill="#fff" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1">
                    <path d="m17 14a1 1 0 0 1 -1 1h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1zm-4 3h-5a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2zm9-6.515v8.515a5.006 5.006 0 0 1 -5 5h-10a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486a6.951 6.951 0 0 1 2.051 4.949zm-6.949-7.021a5.01 5.01 0 0 0 -1.051-.78v4.316a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0 -.781-1.05zm4.949 7.021c0-.165-.032-.323-.047-.485h-4.953a3 3 0 0 1 -3-3v-4.953c-.162-.015-.321-.047-.485-.047h-4.515a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3z" />
                  </svg>
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/quote"
                  className={`${
                    location.pathname.includes("quote") && "active"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#656565"
                      d="M22 8.5a6.5 6.5 0 01-7.02 6.48 6.509 6.509 0 00-5.96-5.96A6.5 6.5 0 1122 8.5z"
                    ></path>
                    <path
                      fill="#656565"
                      d="M15 15.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                      opacity="0.4"
                    ></path>
                    <path
                      fill="#656565"
                      d="M7.62 14.62L8.5 13l.88 1.62 1.62.88-1.62.88L8.5 18l-.88-1.62L6 15.5l1.62-.88z"
                    ></path>
                  </svg>
                </Link>
              </li> */}
            </ul>
            <ul className="admin-ui-sidebar-list list-unstyled ">
              <li>
                <Link
                  to="/settings"
                  className={`my-settings-step ${location.pathname == "/settings" && "active"
                    }`}
                >
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#f1f1f1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-building-bank"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 21l18 0" /><path d="M3 10l18 0" /><path d="M5 6l7 -3l7 3" /><path d="M4 10l0 11" /><path d="M20 10l0 11" /><path d="M8 14l0 3" /><path d="M12 14l0 3" /><path d="M16 14l0 3" /></svg> */}
                  <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24" fill="#fff">
                    <path d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" />
                    <path d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,0,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.705,4.9a3,3,0,0,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29l-.444.256a3,3,0,1,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,0,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,0,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732l1.084-.626a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,0,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732l-1.084.626A1,1,0,0,0,18.746,10.125Z" />
                  </svg>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
