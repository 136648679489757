import React, { useState, useEffect } from "react";
import { Button, Image, InputGroup, Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import {
  emailVerifyStart,
  forgotPasswordStart,
  tfaAuthenticationStart,
  verifyForgotPasswordStart,
} from "../../store/slices/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../Helper/NotificationMessage";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation } from "react-multi-lang";
import * as Yup from "yup";
import CopyToClipboard from "react-copy-to-clipboard";
import NoDataFound from "../Helper/NoDataFound";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Authenticator = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("settings.setting");
  const emailVerify = useSelector((state) => state.admin.emailVerify);
  const tfaAuthentication = useSelector(
    (state) => state.admin.tfaAuthentication
  );
  const [otp, setOtp] = useState("");
  const [skipRender, setSkipRender] = useState(true);
  const [tfaShowPassword, setTfaShowPassword] = useState(true);
  const [step, setStep] = useState(1);

  const tfaSchema = Yup.object().shape({
    otp: Yup.string().required(t("required")),
    // password: Yup.string()
    //   .required(t("required"))
    //   .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid")),
  });

  const handleSubmit = (values) => {
    if (otp) {
      if (otp.length === 6) {
        dispatch(
          tfaAuthenticationStart({
            verification_code: otp,
            email: props.authenticatorModal.email,
            password: props.authenticatorModal.password,
            from_method: props.login ? "login" : "register",
            ...values,
          })
        );
      } else {
        getErrorNotificationMessage(
          "The verification code should be 6 digits."
        );
      }
    } else {
      getErrorNotificationMessage("The verification code field is required.");
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !tfaAuthentication.loading &&
      Object.keys(tfaAuthentication.data).length > 0
    ) {
      // props.closeAuthenticatorModal();
      setStep(2);
    }
    setSkipRender(false);
  }, [tfaAuthentication]);

  return (
    <>
      <Modal
        size="md"
        centered
        show={props.authenticatorModal}
        onHide={props.closeAuthenticatorModal}
        className="otp-validation-model"
        backdrop="static"
      >
        {step == 1 ? (
          <Modal.Header closeButton></Modal.Header>
        ) : (
          <Modal.Header></Modal.Header>
        )}
        <Modal.Body>
          <div className="sign-up-verification">
            {step == 1 ? (
              <React.Fragment>
                <div className="otp-frame-title">
                  <LazyLoadImage
                    effect="blur"
                      src={
                        window.location.origin + "/img/feature/two-step-frame.svg"
                      }
                      className="step-avater-frame-img"
                    />
                  <h2>
                    {props.authenticatorModal.type == "email"
                      ? t("otp_verification")
                      : t("input_virtual")}
                  </h2>
                </div>
                {Object.keys(props.authentication.data).length > 0 ? (
                  <>
                    <div className="efi-bank-step-authentication-content p-0">
                      <img
                        src={`data:image/svg+xml;utf8,${encodeURIComponent(
                          props.authentication.data.qr_code
                        )}`}
                        alt="QR Code"
                        className="mb-3"
                        style={{
                          display: "flex",
                          margin: "auto",
                        }}
                      />
                      <Formik
                        initialValues={{
                          otp: "",
                          // password: "",
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={tfaSchema}
                      >
                        {({ values, setFieldValue }) => (
                          <FORM className="efi-bank-default-form">
                            <ul className="list-unstyled">
                              <li>
                                <div className="efi-bank-step-authentic-card">
                                  <div className="efi-bank-step-count">1</div>
                                  <div className="efi-bank-step-info">
                                    <p>{t("scan_qr_code_using_auth_app")}</p>
                                    <h5>
                                      <span>
                                        {" "}
                                        {
                                          props.authentication.data
                                            .google2fa_secret
                                        }{" "}
                                      </span>{" "}
                                      <CopyToClipboard
                                        text={
                                          props.authentication.data
                                            .google2fa_secret
                                        }
                                      >
                                        <Button
                                          className="copy-btn"
                                          onClick={() =>
                                            getSuccessNotificationMessage(
                                              "Copied!"
                                            )
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="#1b3d86"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            />
                                            <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                            <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                          </svg>
                                        </Button>
                                      </CopyToClipboard>{" "}
                                    </h5>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="efi-bank-step-authentic-card">
                                  <div className="efi-bank-step-count">2</div>
                                  <div className="efi-bank-step-info">
                                    <p>{t("enter_six_configuration_code")}</p>
                                    <div className="verification-item">
                                      <OtpInput
                                        className="otp-box"
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={6}
                                        inputType="tel"
                                        // renderSeparator={<span>-</span>}
                                        renderInput={(props) => (
                                          <input {...props} />
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                              {/* <li>
                            <div className="efi-bank-step-authentic-card">
                              <div className="efi-bank-step-count">3</div>
                              <div className="efi-bank-default-form ">
                                <p>{t("password.enter_your_password")}</p>
                                <div className="mb-3" style={{ width: "100%" }}>
                                  <InputGroup>
                                    <Field
                                      className="form-control"
                                      name="password"
                                      type={
                                        !tfaShowPassword ? "text" : "password"
                                      }
                                      placeholder={t("password.placeholder")}
                                      aria-label="Password"
                                      aria-describedby="Password-addon"
                                    />
                                    <InputGroup.Text
                                      className="password-icon"
                                      onClick={() =>
                                        setTfaShowPassword(!tfaShowPassword)
                                      }
                                    >
                                      {!tfaShowPassword ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="#dde1e6"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          class="icon icon-tabler icons-tabler-outline icon-tabler-eye"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          />
                                          <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                          <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="#dde1e6"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          class="icon icon-tabler icons-tabler-outline icon-tabler-eye-off"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          />
                                          <path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" />
                                          <path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" />
                                          <path d="M3 3l18 18" />
                                        </svg>
                                      )}
                                    </InputGroup.Text>
                                  </InputGroup>
                                  <ErrorMessage
                                    component={"div"}
                                    name="password"
                                    className="errorMsg"
                                  />
                                </div>
                              </div>
                            </div>
                          </li> */}
                            </ul>
                            <div className="efi-bank-default-form">
                              <div className="form-modal-footer">
                                <Button
                                  type="submit"
                                  className="efi-primary-btn w-100"
                                  onClick={() => handleSubmit(values)}
                                  disabled={tfaAuthentication.buttonDisable}
                                >
                                  {tfaAuthentication.buttonDisable ? (
                                    <ButtonLoader />
                                  ) : (
                                    t("activate")
                                  )}
                                </Button>
                              </div>
                              <div className="form-modal-footer mt-1">
                                <Button
                                  type="button"
                                  className="efi-primary-btn w-100"
                                  onClick={() => navigate("/")}
                                  disabled={tfaAuthentication.buttonDisable}
                                >
                                  {t("skip")} 
                                </Button>
                              </div>
                            </div>
                          </FORM>
                        )}
                      </Formik>
                    </div>
                  </>
                ) : null}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="backup-code-modal-sec">
                  <h3>Backup Codes</h3>
                  {tfaAuthentication.data.backup_codes.length > 0 ? (
                    <React.Fragment>
                      <p>
                        <strong>Don’t lose these codes.</strong> Copy them. Each
                        code can only be used once. After you’ve used all 10
                        codes, we’ll give you 10 new codes. Keep your codes as
                        safe as your password.
                      </p>
                      <div className="backup-code-box">
                        {tfaAuthentication.data.backup_codes.map(
                          (item, key) => (
                            <div key={key} className="backup-code-card">
                              {item}
                            </div>
                          )
                        )}
                      </div>
                      <div className="backup-code-btn-sec">
                        <CopyToClipboard
                          text={tfaAuthentication.data.backup_codes.join(", ")}
                        >
                          <Button
                            className="profile-action-btn"
                            onClick={() =>
                              getSuccessNotificationMessage("Copied!")
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#171717"
                                d="M16 12.9v4.2c0 3.5-1.4 4.9-4.9 4.9H6.9C3.4 22 2 20.6 2 17.1v-4.2C2 9.4 3.4 8 6.9 8h4.2c3.5 0 4.9 1.4 4.9 4.9z"
                              ></path>
                              <path
                                fill="#171717"
                                d="M17.1 2h-4.2C9.45 2 8.05 3.37 8.01 6.75h3.09c4.2 0 6.15 1.95 6.15 6.15v3.09c3.38-.04 4.75-1.44 4.75-4.89V6.9C22 3.4 20.6 2 17.1 2z"
                                opacity="0.4"
                              ></path>
                            </svg>
                            Copy
                          </Button>
                        </CopyToClipboard>
                        <Button
                          onClick={() => navigate("/onboarding")}
                          className="efi-primary-btn"
                        >
                          Continue
                        </Button>
                      </div>
                    </React.Fragment>
                  ) : (
                    <NoDataFound />
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Authenticator;
