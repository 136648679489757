import React, { useState, useEffect, useRef } from "react";
import { Button, Table, Dropdown, InputGroup } from "react-bootstrap";
import ContactCreateModal from "./ContactCreateModal";
import ContactDeleteModal from "./ContactDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { contactsListStart } from "../../store/slices/AccountsSlice";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import configuration from "react-global-configuration";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import SendMoneyModal from "./SendMoneyModal";
import OtpVerification from "../Card/OtpVerification";
import { useTranslation } from "react-multi-lang";
import { Tooltip } from "react-tooltip";
import { Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import Pagination from "../Helper/Pagination";

const ContactIndex = (props) => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [searchParams] = useSearchParams();
  const t = useTranslation("contact.contact_list");

  const profile = useSelector((state) => state.admin.profile);
  const contactsList = useSelector((state) => state.accounts.contactsList);

  const [modalShow, deleteModalShow] = React.useState(false);
  const [createmodalShow, createModalShow] = React.useState(false);
  const [moneyModal, setMoneyModal] = useState(false);
  const [showOTPVerifyModal, setShowOTPVerifyModal] = useState(false);
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    status: "",
  });

  const validationSchema = Yup.object().shape({
    search_key: Yup.string()
      .required(t("required"))
      .matches(/^(?=\S*$)/, t("white_space")),
  });

  // useEffect(() => {
  //   dispatch(contactsListStart());
  // }, []);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      if (profile.data.onboarding >= 4) {
        if (!contactsList.buttonDisable) {
          dispatch(
            contactsListStart({
              ...filter,
              skip: 12 * (page - 1),
              take: 12,
            })
          );
        }
      } else {
        getErrorNotificationMessage(
          "Please complete the onboarding to continue."
        );
      }
    }
  }, [profile, filter, page]);

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    navigate(`/recipients?${params}page=${selected + 1}`);
  };

  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="contact-list-card-area">
          <div className="payment-table-header justify-content-between">
            <h3>{t("heading")}</h3>
            <div className="payment-table-header-right">
              <div className="header-search">
                <Formik
                  initialValues={{
                    search_key: searchParams.get("search_key")
                      ? searchParams.get("search_key")
                      : "",
                    sort_by: searchParams.get("sort_by")
                      ? searchParams.get("sort_by")
                      : filter.sort_by,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(val) => {
                    setFilter({
                      ...filter,
                      search_key: val.search_key,
                      // from_date: dateValues.from_date,
                      // to_date: dateValues.to_date,
                    });

                    navigate(
                      searchParams.get("sort_by")
                        ? `/recipients?search_key=${
                            val.search_key
                          }&sort_by=${searchParams.get("sort_by")}`
                        : `/recipients?search_key=${val.search_key}`
                    );
                  }}
                  innerRef={formRef}
                >
                  {({ values, setFieldValue, resetForm }) => (
                    <FORM className="form">
                      <div className="efi-transation-search">
                        <InputGroup className="mb-0">
                          <Field
                            // placeholder={t("search_placeholder")}
                            placeholder={t("search")}
                            type="text"
                            className="form-control trans-form-control"
                            name="search_key"
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                formRef.current.handleSubmit();
                              }
                            }}
                            disabled={contactsList.buttonDisable}
                          />
                          {searchParams.get("search_key") ? (
                            <InputGroup.Text id="basic-addon1">
                              <Button
                                className="search-btn"
                                onClick={() => {
                                  setFieldValue("search_key", "");
                                  setFilter({
                                    ...filter,
                                    search_key: "",
                                  });
                                  navigate(
                                    searchParams.get("sort_by")
                                      ? `/recipients?sort_by=${searchParams.get(
                                          "sort_by"
                                        )}`
                                      : `/recipients`
                                  );
                                }}
                              >
                                <svg
                                  height="20"
                                  width="20"
                                  viewBox="0 0 20 20"
                                  aria-hidden="true"
                                  focusable="false"
                                  class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                                >
                                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                </svg>
                              </Button>
                            </InputGroup.Text>
                          ) : (
                            <InputGroup.Text id="basic-addon1">
                              <Button className="search-btn" type="submit">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#333"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  // class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                                  <path d="M21 21l-6 -6" />
                                </svg>
                              </Button>
                            </InputGroup.Text>
                          )}
                        </InputGroup>
                      </div>
                    </FORM>
                  )}
                </Formik>
              </div>
              <Button
                className="efi-primary-btn"
                onClick={() =>
                  profile.data.onboarding != 4
                  ? getErrorNotificationMessage("Please complete onboarding first!")
                  : setMoneyModal(true)
                }
                disabled={contactsList.buttonDisable}
              >
                {t("send_money")}
              </Button>
              <Button
                className="efi-primary-btn"
                disabled={contactsList.buttonDisable}
                onClick={() =>
                  profile.data.onboarding != 4
                    ? getErrorNotificationMessage(
                        "Please complete onboarding first!"
                      )
                    : configuration.get("configData.stylopay_service_status") ==
                      1
                    ? createModalShow(true)
                    : getErrorNotificationMessage(
                        "Service not available, Please try again later!"
                      )
                }
              >
                {t("create_contact")}
              </Button>
            </div>
          </div>
          {contactsList.loading ? (
            <>
              {[...Array(6)].map((_, key) => (
                <Skeleton key={key} className="mb-3" height={80} width={"100%"} />
              ))}
            </>
          ) : Object.keys(contactsList.data).length > 0 ? (
            contactsList.data.m_contacts.length > 0 ? (
              <>
                <Table className="common-table" responsive>
                  <thead className="common-table-header">
                    <tr>
                      {/* <th>{t("id")}</th> */}
                      {/* <th>IBAN</th> */}
                      <th>{t("name")}</th>
                      <th>{t("email")}</th>
                      <th>{t("mobile")}</th>
                      <th>{t("va_number")}</th>
                      <th>{t("actions")}</th>
                    </tr>
                  </thead>
                  <tbody className="common-table-body">
                    {contactsList.data.m_contacts.map((item, i) => (
                      <tr key={i}>
                        {/* <td>{item.unique_id}</td> */}
                        {/* <td>{item.iban}</td> */}
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>
                          {"+" + item.mobile_country_code + " " + item.mobile}
                        </td>
                        {/* {profile.data.user_type == 2 ? <td>{item.type}</td> : null} */}
                        <td>{item.account_number || "N/A"}</td>
                        <td className="delete-contact-btn-sec">
                          <Link
                            to={`/contact-details-view/${item.unique_id}`}
                            className="view efi-overlay-btn btn-action"
                          >
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlSpace="preserve"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <g fill="#1b3d86">
                              <path
                                d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.91 4.91 0 0 0 0 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.91 4.91 0 0 0 0-5.162m-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.92 2.92 0 0 1 0-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.92 2.92 0 0 1 0 3.068"
                                data-original="#000000"
                              ></path>
                              <path
                                d="M12 7a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5m0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3"
                                data-original="#000000"
                              ></path>
                            </g>
                          </svg>
                            {/* {t("view")} */}
                          </Link>
                          <Tooltip
                            anchorSelect=".view"
                            place="bottom"
                            className="tooltip-element-box"
                          >
                            {t("view")}
                          </Tooltip>
                          <Link
                            onClick={() =>
                              profile.data.onboarding != 4
                                ? getErrorNotificationMessage(
                                    "Please complete onboarding first!"
                                  )
                                : setMoneyModal(item)
                            }
                            className="send efi-primary-btn btn-action"
                          >
                             <svg
                              xmlns="http://www.w3.org/2000/svg"
                              id="Layer_1"
                              width="20"
                              height="20"
                              data-name="Layer 1"
                              viewBox="0 0 24 24"
                              fill="#fff"
                            >
                              <path d="M21.916 8.727 3.965.282A2.745 2.745 0 0 0 .917.713a2.745 2.745 0 0 0-.745 2.995c.017.043 4.411 8.296 4.411 8.296S.27 20.255.255 20.297a2.74 2.74 0 0 0 .749 2.993 2.76 2.76 0 0 0 1.841.708c.409 0 .819-.092 1.201-.279l17.872-8.438A3.59 3.59 0 0 0 24 12.003c0-1.42-.801-2.675-2.084-3.275ZM2.032 2.967a.75.75 0 0 1 .223-.768.74.74 0 0 1 .838-.116l17.974 8.455c.239.112.438.27.591.462H6.315zm19.034 10.504L3.178 21.917a.74.74 0 0 1-.838-.116.74.74 0 0 1-.223-.769L6.319 13h15.345a1.65 1.65 0 0 1-.597.471Z"></path>
                            </svg>
                            {/* {t("send")} */}
                          </Link>
                          <Tooltip
                            anchorSelect=".send"
                            place="bottom"
                            className="tooltip-element-box"
                          >
                            {t("send")}
                          </Tooltip>
                          <Link
                            onClick={() =>
                              profile.data.onboarding != 4
                                ? getErrorNotificationMessage(
                                    "Please complete onboarding first!"
                                  )
                                : configuration.get(
                                    "configData.stylopay_service_status"
                                  ) == 1
                                ? deleteModalShow(item)
                                : getErrorNotificationMessage(
                                    "Service not available, Please try again later!"
                                  )
                            }
                            className="delete efi-delete-btn btn-action"
                          >
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlSpace="preserve"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <g fill="#ff6a6a">
                              <path
                                d="M21 4h-3.1A5.01 5.01 0 0 0 13 0h-2a5.01 5.01 0 0 0-4.9 4H3a1 1 0 0 0 0 2h1v13a5.006 5.006 0 0 0 5 5h6a5.006 5.006 0 0 0 5-5V6h1a1 1 0 0 0 0-2M11 2h2a3.01 3.01 0 0 1 2.829 2H8.171A3.01 3.01 0 0 1 11 2m7 17a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V6h12Z"
                                data-original="#000000"
                              ></path>
                              <path
                                d="M10 18a1 1 0 0 0 1-1v-6a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1m4 0a1 1 0 0 0 1-1v-6a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1"
                                data-original="#000000"
                              ></path>
                            </g>
                          </svg>
                            {/* {t("delete")} */}
                          </Link>
                          <Tooltip
                            anchorSelect=".delete"
                            place="bottom"
                            className="tooltip-element-box"
                          >
                            {t("delete")}
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {contactsList.data.total_m_contacts > 12 ? (
                  <div className="new-billings-pagination-sec">
                    <Pagination
                      page={page}
                      pageCount={Math.ceil(
                        contactsList.data.total_m_contacts / 12
                      )}
                      handlePageClick={handlePageClick}
                    />
                  </div>
                ) : null}
              </>
            ) : (
              <NoDataFound />
            )
          ) : null}
        </div>
      </div>
      {modalShow && (
        <ContactDeleteModal
          show={modalShow}
          onHide={() => deleteModalShow(false)}
        />
      )}
      {createModalShow && (
        <ContactCreateModal
          show={createmodalShow}
          onHide={() => createModalShow(false)}
        />
      )}
      {moneyModal ? (
        <SendMoneyModal
          show={moneyModal}
          onHide={() => setMoneyModal(false)}
          setShowOTPVerifyModal={setShowOTPVerifyModal}
        />
      ) : null}
      {showOTPVerifyModal ? (
        <OtpVerification
          showOtpVerify={showOTPVerifyModal}
          closeOtpVerifyModal={() => setShowOTPVerifyModal(false)}
        />
      ) : null}
    </>
  );
};
export default ContactIndex;
