import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Form, Dropdown } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import ViewTransactionModal from "../Payment/ViewTransactionHistoryModal";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { DateObject } from "react-multi-date-picker";
import Footer from "react-multi-date-picker/plugins/range_picker_footer";
import { useDispatch, useSelector } from "react-redux";
import "../Payment/payment.css";
import {
  beneficiaryTransactionListDownloadStart,
  beneficiaryTransactionListStart,
  updateBeneficiaryStatusStart,
} from "../../store/slices/BeneficiariesSlice";
import { Field, Form as FORM, Formik } from "formik";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import * as Yup from "yup";
import Select from "react-select";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../Helper/NotificationMessage";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import Pagination from "../Helper/Pagination";
import { accountsStatementsStart } from "../../store/slices/AccountsSlice";
import { ButtonLoader, ButtonLoaderDark } from "../Helper/Loader";
import CopyToClipboard from "react-copy-to-clipboard";
import { Tooltip } from 'react-tooltip';

const BeneficiaryTransactions = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const calenderRefs = useRef(null);
  const [searchParams] = useSearchParams();
  const t = useTranslation("beneficiaries.beneficiary_transactions");

  const beneficiaryTransactionList = useSelector((state) => state.beneficiaries.beneficiaryTransactionList);
  const beneficiaryTransactionListDownload = useSelector((state) => state.beneficiaries.beneficiaryTransactionListDownload);
  const updateBeneficiaryStatus = useSelector((state) => state.beneficiaries.updateBeneficiaryStatus);
  const profile = useSelector((state) => state.admin.profile);
  const accountsStatements = useSelector((state) => state.accounts.accountsStatements);

  // const [startDate, setStartDate] = useState(new Date("2024/04/08"));
  // const [endDate, setEndDate] = useState(new Date("2024/02/10"));
  const [viewTransactionHistory, setViewTransactionHistory] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [typeStatus, setTypeStatus] = useState(false);
  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    status: "",
  });
  const [close, setClose] = useState(false);
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [dateValues, setDateValues] = useState({
    from_date: "",
    to_date: "",
  });
  const [value, setValue] = useState([null, null]);

  const type_options = [
    { value: "1", label: t("status_options.initiated") },
    { value: "2", label: t("status_options.processing") },
    { value: "3", label: t("status_options.completed") },
    { value: "4", label: t("status_options.failed") },
    { value: "5", label: t("status_options.expired") },
  ];

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      if (profile.data.onboarding >= 4) {
        if (!beneficiaryTransactionList.buttonDisable) {
          dispatch(
            beneficiaryTransactionListStart({
              ...filter,
              skip: 12 * (page - 1),
              take: 12,
            })
          );
        }
      } else {
        getErrorNotificationMessage("Please complete the onboarding to continue.")
      }
    }
  }, [profile, filter, page]);

  // const handleExport = () => {
  //   dispatch(
  //     accountsStatementsStart({
  //       ...filter,
  //       from_date: dateValues.from_date
  //         ?.toString()
  //         .split("-")
  //         .reverse()
  //         .join("-"),
  //       to_date: dateValues.to_date?.toString().split("-").reverse().join("-"),
  //     })
  //   );
  // };

  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return "payment-initiated";
      case 2:
        return "payment-processing";
      case 3:
        return "payment-completed";
      case 4:
        return "payment-failed";
      case 5:
        return "payment-expired"
      default:
        return "payment-initiated";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 1:
        return "#5C9EEB";
      case 2:
        return "#FFCE22";
      case 3:
        return "#197E23";
      case 4:
        return "#FF8A00";
      default:
        return "#5C9EEB";
    }
  };

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    navigate(`/beneficiary-transactions?${params}page=${selected + 1}`);
  };

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
      setFilter({
        ...filter,
        search_key: searchParams.get("search_key")
          ? searchParams.get("search_key")
          : "",
        from_date: dateValues.from_date,
        to_date: dateValues.to_date,
      });
    }
    setSkipRender(false);
  }, [searchParams.get("page"), searchParams.get("search_key")]);

  useEffect(() => {
    if (
      !skipRender &&
      !accountsStatements.buttonDisable &&
      Object.keys(accountsStatements.data).length > 0
    ) {
      accountsStatements.data?.url &&
        window.open(accountsStatements.data.url, "_blank");
    }
    setSkipRender(false);
  }, [accountsStatements]);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E5E8EF!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "180px",
      cursor: "pointer",
      fontSize: "0.95em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.95em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#1b3d86",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.95em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#1b3d86!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#878E96",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#1b3d86"
        : state.isFocused
          ? "#f0f0f0"
          : "transparent",
      color: state.isSelected ? "#fff" : state.isFocused ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#f0f0f0",
        color: "#000",
      },
    }),
  };

  const validationSchema = Yup.object().shape({
    search_key: Yup.string()
      .required(t("required"))
      .matches(/^(?=\S*$)/, t("white_space")),
  });

  const handleDateChange = (values) => {
    if (values) {
      setValue(values);
      formRef?.current?.setFieldValue(
        "from_date",
        values && values.length > 0
          ? JSON.stringify(new Date(values[0])).slice(1, 11).split("-").reverse().join("-")
          : ""
      );
      formRef?.current?.setFieldValue(
        "to_date",
        values && values.length > 1
          ? JSON.stringify(new Date(values[1])).slice(1, 11).split("-").reverse().join("-")
          : ""
      );
      setDateValues({
        from_date:
          values.length > 0
            ? JSON.stringify(new Date(values[0])).slice(1, 11).split("-").reverse().join("-")
            : "",
        to_date:
          values.length > 1
            ? JSON.stringify(new Date(values[1])).slice(1, 11).split("-").reverse().join("-")
            : "",
      });
    } else {
      setDateValues({
        from_date: "",
        to_date: "",
      });
      formRef?.current?.setFieldValue("from_date", "");
      formRef?.current?.setFieldValue("to_date", "");
      calenderRefs.current.closeCalendar();
      setFilter({
        ...filter,
        skip: 12 * (page - 1),
        take: 12,
        from_date: "",
        to_date: "",
      });
    }
  };

  useEffect(() => {
    if (!skipRender && !beneficiaryTransactionListDownload.buttonDisable && Object.keys(beneficiaryTransactionListDownload.data).length > 0) {
      window.open(beneficiaryTransactionListDownload.data.url, "blank")
    }
    setSkipRender(false);
  }, [beneficiaryTransactionListDownload])

  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="common-full-card">
          <div className="efi-feature-header-frame layout-between">
            <div className="efi-feature-transation-title">
              <h2>
                {t("heading")}
                <span className="ml-3">
                  {beneficiaryTransactionList.data?.total_beneficiary_transactions || 0}
                </span>
              </h2>
            </div>
            {profile.data.onboarding >= 4 ?
              <div className="efi-feature-transation-search-frame">
                <Select
                  options={type_options}
                  className="select-payout"
                  styles={customStyles}
                  isSearchable={false}
                  isClearable
                  value={typeStatus || null}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setFilter({
                        ...filter,
                        status: selectedOption.value,
                      });
                      setTypeStatus(selectedOption);
                      navigate(
                        filter.search_key
                          ? `/beneficiary-transactions?search_key=${filter.search_key}&status=${selectedOption.value}`
                          : `/beneficiary-transactions?status=${selectedOption.value}`
                      );
                    } else {
                      const newFilter = { ...filter };
                      delete newFilter.status;
                      setFilter(newFilter);
                      navigate(
                        filter.search_key
                          ? `/beneficiary-transactions?search_key=${filter.search_key}`
                          : "/beneficiary-transactions"
                      );
                      setTypeStatus(null);
                    }
                  }}
                  placeholder={
                    <div className="placeholder-flex">{t("status")}</div>
                  }
                  isDisabled={beneficiaryTransactionList.buttonDisable}
                />
                <div className="header-search">
                  <Formik
                    initialValues={{
                      search_key: searchParams.get("search_key")
                        ? searchParams.get("search_key")
                        : "",
                      sort_by: searchParams.get("sort_by")
                        ? searchParams.get("sort_by")
                        : filter.sort_by,
                      from_date: "",
                      to_date: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(val) => {
                      setFilter({
                        ...filter,
                        search_key: val.search_key,
                        from_date: dateValues.from_date,
                        to_date: dateValues.to_date,
                      });

                      navigate(
                        searchParams.get("sort_by")
                          ? `/beneficiary-transactions?search_key=${val.search_key
                          }&sort_by=${searchParams.get("sort_by")}`
                          : `/beneficiary-transactions?search_key=${val.search_key}`,
                        { state: { value: value } }
                      );
                    }}
                    innerRef={formRef}
                  >
                    {({ values, setFieldValue, resetForm }) => (
                      <FORM className="form">
                        <InputGroup
                          className={`d-flex date-sec mb-0 efi-data-input ${close ? "date-close" : ""
                            }`}
                        >
                          <DatePicker
                            value={value}
                            style={{ padding: "1em" }}
                            onChange={(date) => {
                              handleDateChange(date);
                            }}
                            range
                            numberOfMonths={2}
                            ref={calenderRefs}
                            format="DD-MM-YYYY"
                            maxDate={
                              new Date(new Date().setHours(23, 59, 59, 999))
                            }
                            id="date-picker-input"
                            placeholder={t("date.date_range")}
                            // render={<InputIcon/>}
                            editable={false}
                            onOpenPickNewDate={false}
                            disabled={beneficiaryTransactionList.buttonDisable}
                          >
                            <div
                              className="d-flex justify-content-end gap-2"
                              style={{ padding: "1em" }}
                            >
                              <Button
                                className="efi-datePicker-btn-cancel"
                                onClick={() => {
                                  calenderRefs.current.closeCalendar();
                                  setDateValues({
                                    from_date: "",
                                    to_date: "",
                                  });
                                  setValue([null, null]);
                                  setClose(false);
                                  // setFilter({
                                  //   ...filter,
                                  //   from_date: "",
                                  //   to_date: "",
                                  // });
                                }}
                              >
                                {t("close")}
                              </Button>
                              <Button
                                className="efi-datePicker-btn"
                                onClick={() => {
                                  if (values.from_date && values.to_date) {                                    setFilter({
                                      ...filter,
                                      skip: 12 * (page - 1),
                                      take: 12,
                                      from_date: dateValues.from_date,
                                      to_date: dateValues.to_date,
                                    });
                                    calenderRefs.current.closeCalendar();
                                    setClose(true);
                                  } else
                                    getErrorNotificationMessage(
                                      t("date.select_date")
                                    );
                                }}
                              >
                                {t("apply")}
                              </Button>
                            </div>
                          </DatePicker>
                          {close ? (
                            <>
                              <button
                                type="button"
                                className="search-btn date-cancel"
                                onClick={() => {
                                  // setValue([null , new Date()]);
                                  setDateValues({
                                    from_date: "",
                                    to_date: "",
                                  });
                                  setValue([null, null]);
                                  setClose(false);
                                  setFilter({
                                    ...filter,
                                    from_date: "",
                                    to_date: "",
                                  });
                                }}
                              >
                                <svg
                                  height="24"
                                  width="24"
                                  viewBox="0 0 20 20"
                                  aria-hidden="true"
                                  focusable="false"
                                  class="css-tj5bde-Svg"
                                >
                                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                </svg>
                              </button>
                            </>
                          ) : (
                            <InputGroup.Text
                              onClick={() => {
                                const input =
                                  document.getElementById("date-picker-input");
                                if (input) {
                                  input.focus();
                                }
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                fill="none"
                                viewBox="0 0 18 18"
                              >
                                <g clipPath="url(#clip0_47_6)">
                                  <path
                                    fill="#8F9899"
                                    d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_47_6">
                                    <path fill="#fff" d="M0 0H18V18H0z"></path>
                                  </clipPath>
                                </defs>
                              </svg>
                            </InputGroup.Text>
                          )}
                        </InputGroup>
                        <div className="efi-transation-search">
                          <InputGroup className="mb-0">
                            <Field
                              // placeholder={t("search_placeholder")}
                              placeholder={t("search")}
                              type="text"
                              className="form-control trans-form-control"
                              name="search_key"
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  formRef.current.handleSubmit();
                                }
                              }}
                              disabled={beneficiaryTransactionList.buttonDisable}
                            />
                            {searchParams.get("search_key") ? (
                              <InputGroup.Text id="basic-addon1">
                                <Button
                                  className="search-btn"
                                  onClick={() => {
                                    setFieldValue("search_key", "");
                                    setFilter({
                                      ...filter,
                                      search_key: "",
                                      from_date: dateValues.from_date,
                                      to_date: dateValues.to_date,
                                    });
                                    navigate(
                                      searchParams.get("sort_by")
                                        ? `/beneficiary-transactions?sort_by=${searchParams.get(
                                          "sort_by"
                                        )}`
                                        : `/beneficiary-transactions`
                                    );
                                  }}
                                >
                                  <svg
                                    height="20"
                                    width="20"
                                    viewBox="0 0 20 20"
                                    aria-hidden="true"
                                    focusable="false"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                                  >
                                    <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                  </svg>
                                </Button>
                              </InputGroup.Text>
                            ) : (
                              <InputGroup.Text id="basic-addon1">
                                <Button className="search-btn" type="submit">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#333"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  // class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    />
                                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                                    <path d="M21 21l-6 -6" />
                                  </svg>
                                </Button>
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                          <Dropdown className="download-dropdown">
                            <Dropdown.Toggle id="dropdown-basic" disabled={beneficiaryTransactionList.buttonDisable || beneficiaryTransactionListDownload.buttonDisable || !(beneficiaryTransactionList.data?.beneficiary_transactions?.length > 0)}>
                              {beneficiaryTransactionListDownload.buttonDisable ? (
                                <ButtonLoaderDark />
                              ) : (
                                <svg
                                  style={{ display: "flex", margin: "auto" }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="icon icon-tabler icons-tabler-outline icon-tabler-download"
                                >
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                  <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                                  <path d="M7 11l5 5l5 -5" />
                                  <path d="M12 4l0 12" />
                                </svg>
                              )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => dispatch(beneficiaryTransactionListDownloadStart({
                                ...filter,
                                type: 1,
                              }))}>{t("pdf")}</Dropdown.Item>
                              <Dropdown.Item onClick={() => dispatch(beneficiaryTransactionListDownloadStart({
                                ...filter,
                                type: 2,
                              }))}>{t("excel")}</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </div>
                {/* <Button
                  className="download-btn"
                  type="button"
                  onClick={() => dispatch(beneficiaryTransactionListDownloadStart(filter))}
                  disabled={
                    beneficiaryTransactionList.buttonDisable || beneficiaryTransactionListDownload.buttonDisable || !(beneficiaryTransactionList.data?.beneficiary_transactions?.length > 0)
                  }
                >
                  {beneficiaryTransactionListDownload.buttonDisable ? (
                    <ButtonLoaderDark />
                  ) : (
                    <svg
                      style={{ display: "flex", margin: "auto" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-download"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                      <path d="M7 11l5 5l5 -5" />
                      <path d="M12 4l0 12" />
                    </svg>
                  )}
                </Button> */}
                
              </div>
              :
              null}
          </div>
          {beneficiaryTransactionList.buttonDisable ?
            <>
              {[...Array(6)].map((_, key) => (
                <Skeleton key={key} className="mb-3" height={80} width={"100%"} />
              ))}
            </>
            : Object.keys(beneficiaryTransactionList.data).length > 0 &&
              beneficiaryTransactionList.data.beneficiary_transactions.length > 0
              ?
              <>
                <Table className="common-table" responsive>
                  <thead className="common-table-header">
                    <tr>
                      {/* <th>{t("id")}</th> */}
                      <th>{t("date.name")}</th>
                      <th>{t("account_holder_name")}</th>
                      <th>{t("transaction_id")}</th>
                      <th>{t("account_number")}</th>
                      <th>{t("ifsc_code")}</th>
                      <th>{t("amount")}</th>
                      <th>{t("recipient_amount")}</th>
                      <th>{t("status")}</th>
                      <th>{t("action")}</th>
                    </tr>
                  </thead>
                  <tbody className="common-table-body">
                    {Object.keys(beneficiaryTransactionList.data).length > 0 &&
                      beneficiaryTransactionList.data.beneficiary_transactions.length > 0 ? (
                      beneficiaryTransactionList.data.beneficiary_transactions.map(
                        (transaction, i) => (
                          <tr key={i}>
                            {/* <td>{transaction.unique_id}</td> */}
                            <td>{transaction.created_at_formatted}</td>
                            <td>{transaction.account_holder_name || "N/A"}</td>
                            <td>
                              {transaction.transaction_id ? (
                                <>
                                  {transaction.transaction_id.slice(0, 3) +
                                    "..." +
                                    transaction.transaction_id.slice(-3)}{" "}
                                  <CopyToClipboard
                                    text={transaction.transaction_id}
                                  >
                                    <Button
                                      className="copy-btn"
                                      onClick={() =>
                                        getSuccessNotificationMessage("Copied!")
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="#1b3d86"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                        <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                      </svg>
                                    </Button>
                                  </CopyToClipboard>
                                </>
                              ) : (
                                "N/A"
                              )}
                            </td>
                            <td>{transaction.account_number}</td>
                            <td className="transaction-code">{transaction.code}</td>
                            {/* <td>
                                <div className="d-flex align-items-center gap-2">
                                  <span className="efi-trade-card">
                                    {transaction.type == 1 ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        enableBackground="new 0 0 512 512"
                                        viewBox="0 0 28 28"
                                      >
                                        <switch>
                                          <g>
                                            <path
                                              fill="#30a75f"
                                              d="M24.7 4.7L6.4 23H20c.6 0 1 .4 1 1s-.4 1-1 1H4c-.6 0-1-.4-1-1V8c0-.6.4-1 1-1s1 .4 1 1v13.6L23.3 3.3c.4-.4 1-.4 1.4 0s.4 1 0 1.4z"
                                              data-original="#88428d"
                                            ></path>
                                          </g>
                                        </switch>
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        enableBackground="new 0 0 512 512"
                                        viewBox="0 0 28 28"
                                        className="rote"
                                      >
                                        <switch>
                                          <g>
                                            <path
                                              fill="#EB6363"
                                              d="M24.7 4.7L6.4 23H20c.6 0 1 .4 1 1s-.4 1-1 1H4c-.6 0-1-.4-1-1V8c0-.6.4-1 1-1s1 .4 1 1v13.6L23.3 3.3c.4-.4 1-.4 1.4 0s.4 1 0 1.4z"
                                              data-original="#88428d"
                                            ></path>
                                          </g>
                                        </switch>
                                      </svg>
                                    )}
                                  </span>
                                  <p className="mb-0 text-fit">
                                    {" "}
                                    {transaction.remarks}
                                  </p>
                                </div>
                              </td> */}
                            {/* <td>{transaction.name}</td> */}
                            <td>
                              {transaction.type == 1 ? (
                                <span className="color-green">
                                  + {transaction.amount}
                                </span>
                              ) : (
                                <span className="color-red">
                                  - {transaction.amount}
                                </span>
                              )}
                            </td>
                            <td>
                              {transaction.recipient_amount + " " + transaction.recipient_currency}
                            </td>
                            <td>
                              {/* <div className="settlement-status-cell"> */}
                              <div className={getStatusClass(transaction.status)}>
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  fill={getStatusColor(
                                    transaction.status
                                  )}
                                  data-name="Layer 1"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0z"></path>
                                </svg> */}
                                {transaction.status_formatted}
                              </div>
                              {/* </div> */}
                            </td>
                            <td className="delete-contact-btn-sec">
                              <div className="d-flex   justify-content-center transaction-btn ">
                                {transaction.status == 1 ||
                                  transaction.status == 2 ? (
                                    <>
                                  <Button
                                    disabled={
                                      updateBeneficiaryStatus.buttonDisable
                                    }
                                    className="check efi-overlay-btn btn-action"
                                    onClick={() => {
                                      setTransactionId(transaction.id);
                                      dispatch(
                                        updateBeneficiaryStatusStart({
                                          beneficiary_transaction_unique_id:
                                            transaction.unique_id,
                                        })
                                      );
                                    }}
                                  >
                                    {updateBeneficiaryStatus.buttonDisable &&
                                      transactionId === transaction.id ? (
                                      <ButtonLoader />
                                    ) : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            id="Layer_1"
                                            data-name="Layer 1"
                                            viewBox="0 0 24 24"
                                            width="20"
                                            height="20"
                                            fill="#1b3d86"
                                          >
                                            <path d="M15.999 15.5a1 1 0 0 1-.548-.164l-4-2.628a1 1 0 0 1-.451-.836V7a1 1 0 1 1 2 0v4.333l3.549 2.331a1 1 0 0 1-.55 1.836m-3.285 6.475A10 10 0 0 1 12 22C6.486 22 2 17.514 2 12S6.486 2 12 2c3.151 0 6.112 1.512 7.988 4H17a1 1 0 1 0 0 2h4c1.103 0 2-.897 2-2V2a1 1 0 1 0-2 0v2.104C18.743 1.543 15.473 0 12 0 5.383 0 0 5.383 0 12s5.383 12 12 12q.432 0 .856-.031a1 1 0 0 0 .926-1.068.99.99 0 0 0-1.068-.926M23 11a1 1 0 0 0-1 1c0 .455-.031.913-.092 1.36a1 1 0 0 0 1.982.27c.072-.536.109-1.085.109-1.63a1 1 0 0 0-1-1Zm-.863 5.396a.997.997 0 0 0-1.357.396c-.217.396-.464.782-.735 1.148a1.002 1.002 0 0 0 .804 1.594c.307 0 .609-.141.806-.405.323-.439.62-.902.88-1.377a1 1 0 0 0-.396-1.357Zm-4.731 4.02a10 10 0 0 1-1.191.656 1 1 0 1 0 .842 1.814c.491-.229.973-.493 1.432-.789a1 1 0 1 0-1.082-1.682Z"></path>
                                          </svg>
                                      // t("check_status")
                                    )}
                                  </Button>
                                  <Tooltip anchorSelect=".check" place="bottom" className="tooltip-element-box">
                                  {t("check_status")}
                                </Tooltip>
                                </>
                                ) : null}
                                <Button
                                  disabled={updateBeneficiaryStatus.buttonDisable}
                                  className="view efi-overlay-btn btn-action"
                                  onClick={() => {
                                    navigate(`/beneficiary-transaction-view/${transaction.unique_id}`);
                                  }}
                                >
                                  <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlSpace="preserve"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <g fill="#1b3d86">
                              <path
                                d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.91 4.91 0 0 0 0 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.91 4.91 0 0 0 0-5.162m-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.92 2.92 0 0 1 0-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.92 2.92 0 0 1 0 3.068"
                                data-original="#000000"
                              ></path>
                              <path
                                d="M12 7a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5m0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3"
                                data-original="#000000"
                              ></path>
                            </g>
                          </svg>
                                  {/* {t("view")} */}
                                </Button>
                                <Tooltip anchorSelect=".view" place="bottom" className="tooltip-element-box">
                                  {t("view")}
                                </Tooltip>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center">
                          <NoDataFound />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {beneficiaryTransactionList.data.total_beneficiary_transactions > 12 ? (
                  <div className="new-billings-pagination-sec">
                    <Pagination
                      page={page}
                      pageCount={Math.ceil(
                        beneficiaryTransactionList.data.total_beneficiary_transactions / 12
                      )}
                      handlePageClick={handlePageClick}
                    />
                  </div>
                ) : null}
              </>
              :
              <NoDataFound />
          }
        </div>
      </div>
      {viewTransactionHistory ? <ViewTransactionModal
        show={viewTransactionHistory}
        onHide={() => setViewTransactionHistory(false)}
      /> : null}
    </>
  );
};

export default BeneficiaryTransactions;
