import React from "react";
import { Container, Image, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration"

const EmptyHeader = (props) => {
  return (
    <>
      <header>
		<Navbar expand="lg">
		<Container fluid>
			<div className="admin-ui-title-sec">
			<div className="admin-user-details pb-0">
				<Link to="/">
				<Image
					style={{ width: "10em" }}
					className="admin-user-img"
					src={configuration.get("configData.cms_logo") || window.location.origin + "/img/logo.png"}
				/>
				</Link>
			</div>
			</div>
		</Container>
		</Navbar>
      </header>
    </>
  );
};

export default EmptyHeader;
