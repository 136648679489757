import React from "react";
import { useTranslation } from "react-multi-lang";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Scatter,
} from "recharts";

const LineGraph = (props) => {

  const t = useTranslation("dashboard")

  const data = props.transactionData.model_data.map((transaction, index) => ({
    name: props.transactionData.days[index],
    Transactions: transaction,
  }));

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="line-chart-custom-tooltip">
          <p className="label">{t("transactions_amount")}{" : "}
            <span>{`$ ${payload[0].value}`}</span>
          </p>
          <p>{`${label}`}</p>
        </div>
      );
    }
  
    return null;
  };

  return (
    <>
      <div className="line-graph">
        <div className="line-graph-body">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              width={500}
              height={400}
              data={data}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis width={40}/>
              <Tooltip content={<CustomTooltip/>} />
              <Area
                type="monotone"
                dataKey={"Transactions"}
                stroke="#1b3d86"
                fill="#e3e6ec"
              />
              <Scatter dataKey="cnt" fill="red" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

export default LineGraph;
