import React, { useEffect, useState, useRef } from "react";
import { Image, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../../Helper/Loader";
import * as Yup from "yup";
import "@djthoms/pretty-checkbox";
import { countries } from "countries-list";
import isEqual from "lodash/isEqual";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { businessInfoUpdateStart } from "../../../store/slices/BusinessSlice";
import iso from "iso-3166-1";
import 'react-datepicker/dist/react-datepicker.css';
import { countriesListStart, nationalitiesListStart } from "../../../store/slices/AdminSlice";
import { getErrorNotificationMessage } from "../../Helper/NotificationMessage";

const BusinessInformation = (props) => {

  const formRef = useRef();
  const dispatch = useDispatch();
  const t = useTranslation("onboarding.onboarding_form.business_information");

  const profile = useSelector((state) => state.admin.profile);
  const businessInfoUpdate = useSelector((state) => state.business.businessInfoUpdate);
  const nationalitiesList = useSelector((state) => state.admin.nationalitiesList);
  const countriesList = useSelector((state) => state.admin.countriesList);

  const [skipRender, setSkipRender] = useState(true);
  const [deliverySameAsBilling, setDeliverySameAsBilling] = useState(false);
  const [countryOptions, setCountryOptions] = useState([])
  const [nationalitiesOptions, setNationalitiesOptions] = useState([])

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid #dbdbdb!important",
      borderRadius: "6px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "300",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#1b3d86!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#757575",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? '#1b3d86' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      ":hover": {
              backgroundColor: "#1b3d86",
              color: "#fff"
            },
  }),
  };

  const taxIdOptions = [
    { label: "EIN", value: "ein" },
    { label: "TIN", value: "tin" },
    { label: "UEN", value: "uen" },
    { label: "ITN", value: "itn" },
    { label: "BRN", value: "brn" },
    { label: "NPWP", value: "npwp" },
    { label: "IN", value: "in" },
  ];

  const transactionOptions = [
    { label: "Cash Deposit", value: "cash_deposit" },
    { label: "Cash Withdrawal", value: "cash_withdrawal" },
    { label: "Forex", value: "forex" },
    { label: "Loan Payments", value: "loan_payments" },
    { label: "Online Payment", value: "online_payment" },
    { label: "Others", value: "others" },
    { label: "Wire Transfers", value: "wire_transfers" },
  ];

  const fundingOptions = [
    { label: "Debt", value: "debt" },
    { label: "Debt Capital", value: "debt_capital" },
    { label: "Directors' Capital", value: "directors_capital" },
    { label: "Equity Capital", value: "equity_capital" },
    { label: "Others", value: "others" },
    { label: "Term Loan", value: "term_loan" },
    { label: "Venture Funding", value: "venture_funding" },
  ];

  const codeValidationRules = {
    EIN: {
      min: 9,
      max: 9,
      regex: /^\d{9}$/,  // Digits only, length is 9
      message: "Invalid EIN code",
    },
    TIN: {
      min: 15,
      max: 15,
      regex: /^[A-Za-z0-9]{15}$/, // Alphanumeric, length 15
      message: "Invalid TIN code",
    },
    UEN: {
      min: 9,
      max: 10,
      regex: /^\d{9,10}$/, // Digits only, length can be 9 or 10
      message: "Invalid UEN code",
    },
    ITN: {
      min: 13,
      max: 13,
      regex: /^\d{13}$/, // Digits only, length is 13
      message: "Invalid ITN code",
    },
    BRN: {
      min: 8,
      max: 8,
      regex: /^\d{8}$/, // Digits only, length is 8
      message: "Invalid BRN code",
    },
    NPWP: {
      min: 16,
      max: 16,
      regex: /^\d{16}$/, // Digits only, length is 16
      message: "Invalid NPWP code",
    },
    IN: {
      min: 11,
      max: 11,
      regex: /^\d{11}$/, // Digits only, length is 11
      message: "Invalid IN code",
    },
  };

  const validationSchema = Yup.object().shape({
    operational_primary_address: Yup.string()
      .required(t("required"))
      .matches(/^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/, t("operational_address_1.invalid"))
      .min(5, t("operational_address_1.min_length_invalid"))
      .notOneOf([""])
      .trim(),
    operational_secondary_address: Yup.string()
      .required(t("required"))
      .matches(/^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/, t("operational_address_2.invalid"))
      .min(5, t("operational_address_2.min_length_invalid"))
      .notOneOf([""])
      .trim(),
    operational_city: Yup.string()
      .required(t("required"))
      .matches(/^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/, t("operational_city.invalid"))
      .min(3, t("operational_city.min_length_invalid"))
      .notOneOf([""])
      .trim(),
    operational_state: Yup.string()
      .required(t("required"))
      .matches(/^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/, t("operational_state.invalid"))
      .min(2, t("operational_state.min_length_invalid"))
      .notOneOf([""])
      .trim(),
    operational_zipcode: Yup.string()
      .required(t("required"))
      .matches(/^[A-Za-z0-9]+$/, t("operational_pincode.invalid"))
      .min(4, t("operational_state.min_length_invalid"))
      .notOneOf([""])
      .trim(),
    operational_country: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("operational_country.invalid"))
      .trim(),
    foundation_primary_address: Yup.string()
      .required(t("required"))
      .matches(/^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/, t("registered_address_1.invalid"))
      .min(5, t("registered_address_1.min_length_invalid"))
      .notOneOf([""])
      .trim(),
    foundation_secondary_address: Yup.string()
      .required(t("required"))
      .matches(/^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/, t("operational_address_2.invalid"))
      .min(5, t("operational_address_2.min_length_invalid"))
      .notOneOf([""])
      .trim(),
    foundation_city: Yup.string()
      .required(t("required"))
      .matches(/^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/, t("operational_city.invalid"))
      .min(3, t("registered_city.min_length_invalid"))
      .notOneOf([""])
      .trim(),
    foundation_state: Yup.string()
      .required(t("required"))
      .matches(/^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/, t("registered_state.invalid"))
      .min(2, t("registered_state.min_length_invalid"))
      .notOneOf([""])
      .trim(),
    foundation_zipcode: Yup.string()
      .required(t("required"))
      .matches(/^[A-Za-z0-9]+$/, t("registered_pincode.invalid"))
      .min(4, t("registered_pincode.min_length_invalid"))
      .notOneOf([""])
      .trim(),
    foundation_country: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("registered_country.invalid"))
      .trim(),
    tax_id_type: Yup.string().required(t("required")),
    // tax_id_number: Yup.string().when("tax_id_type", (tax_id_type, schema) => {
    //   const rule = codeValidationRules[tax_id_type?.toUpperCase()];
    //   if (rule) {
    //     const { min, max, regex, message } = rule;
    //     return Yup.string()
    //       .required(t("required"))
    //       .min(min, message)
    //       .max(max, message)
    //       .matches(regex, message);
    //   }
    //   return schema.required(t("required"));
    // }),
    tax_id_number: Yup.string().required(t("required")).notOneOf([""]).trim(),
    tax_id_issuance_country: Yup.string().required(t("required")),
    foundation_date: Yup.string().required(t("required")),
    tax_id_date_issued: Yup.string().required(t("required")),
    tax_id_date_expiry: Yup.string().required(t(" required")),
    purpose_of_account_opening: Yup.string().required(t("required")),
    source_of_funds: Yup.string().required(t("required")),
    // additional_details: Yup.string().required(t("required")),
    company_name: Yup.string().required(t("required"))
      .matches(/^[a-zA-Z\s]+$/, t('company_name.invalid'))
      .min(1, t("company_name.minimum_characters"))
      .notOneOf([""])
      .trim(),
    preferred_name: Yup.string().required(t("required"))
      .matches(/^[a-zA-Z\s]+$/, t('preferred_name.invalid'))
      .min(1, t("required_note"))
      .notOneOf([""])
      .trim()
  });

  const handleSubmit = (values) => {
    dispatch(businessInfoUpdateStart({
      ...values,
      foundation_date: values.foundation_date.split("-").reverse().join("-"),
      tax_id_date_issued: values.tax_id_date_issued.split("-").reverse().join("-"),
      tax_id_date_expiry: values.tax_id_date_expiry.split("-").reverse().join("-"),
    }));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !businessInfoUpdate.loading &&
      Object.keys(businessInfoUpdate.data).length > 0
    ) {
      props.setStep(props.step + 1);
    }
    setSkipRender(false);
  }, [businessInfoUpdate]);

  const handleCheckboxChange = () => {
    const addressValues = formRef.current.values;
    const operationalValues = {
      primary_address: addressValues.operational_primary_address,
      secondary_address: addressValues.operational_secondary_address,
      country: addressValues.operational_country,
      state: addressValues.operational_state,
      city: addressValues.operational_city,
      zipcode: addressValues.operational_zipcode,
    };
    
    if( Object.values(operationalValues).every(value => value !== "")){
      setDeliverySameAsBilling(!deliverySameAsBilling);
      if (!deliverySameAsBilling) {
        // Copy values from billing address to delivery address fields
        const billingAddress = formRef.current.values;
        formRef.current.setValues({
          ...billingAddress,
          foundation_primary_address: billingAddress.operational_primary_address,
          foundation_secondary_address:
            billingAddress.operational_secondary_address,
          foundation_country: billingAddress.operational_country,
          foundation_state: billingAddress.operational_state,
          foundation_city: billingAddress.operational_city,
          foundation_zipcode: billingAddress.operational_zipcode,
        });
      }
    }
    else{
      getErrorNotificationMessage(t("fill_registered_address"))
    }
  };

  const handleAddressChanges = () => {
    const billingValues = formRef.current.values;
    const deliveryValues = {
      foundation_primary_address: billingValues.operational_primary_address,
      foundation_secondary_address: billingValues.operational_secondary_address,
      foundation_country: billingValues.operational_country,
      foundation_state: billingValues.operational_state,
      foundation_city: billingValues.operational_city,
      foundation_zipcode: billingValues.operational_zipcode,
    };
    const addressesAreEqual = isEqual(billingValues, deliveryValues);
    setDeliverySameAsBilling(addressesAreEqual);
  };

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      const addressValues = formRef.current.values;
      const operationalValues = {
        primary_address: addressValues.operational_primary_address,
        secondary_address: addressValues.operational_secondary_address,
        country: addressValues.operational_country,
        state: addressValues.operational_state,
        city: addressValues.operational_city,
        zipcode: addressValues.operational_zipcode,
      };
      const foundationValues = {
        primary_address: addressValues.foundation_primary_address,
        secondary_address: addressValues.foundation_secondary_address,
        country: addressValues.foundation_country,
        state: addressValues.foundation_state,
        city: addressValues.foundation_city,
        zipcode: addressValues.foundation_zipcode,
      };
      if (isEqual(operationalValues, foundationValues)&&Object.values(foundationValues).every(value => value !== "") && Object.values(operationalValues).every(value => value !== "") ) {
          setDeliverySameAsBilling(true)
      }
    }
  }, [profile]);

  useEffect(() => {
    dispatch(nationalitiesListStart({
      all: 1,
    }));
    dispatch(countriesListStart({
      all: 1,
    }))
  }, []);

  useEffect(() => {
    if (!skipRender && !nationalitiesList.buttonDisable && Object.keys(nationalitiesList.data).length > 0) {
      if (nationalitiesList.data.nationalities.length > 0) {
        setNationalitiesOptions(nationalitiesList.data.nationalities.map((item) => ({
          value: item.alpha_2_code,
          label: item.country_name,
        })))
      }
    }
    setSkipRender(false);
  }, [nationalitiesList]);

  useEffect(() => {
    if (!skipRender && !countriesList.buttonDisable && Object.keys(countriesList.data).length > 0) {
      if (countriesList.data.countries.length > 0) {
        setCountryOptions(countriesList.data.countries.map((item) => ({
          value: item.alpha_2_code,
          label: item.country_name,
        })))
      }
    }
    setSkipRender(false);
  }, [countriesList])

  return (
    <>
      <div className="onboarding-auth billing-addres-form">
        <Row className="justify-content-between mb-3">
          <Col lg={6}>
            <div className="onboarding-auth-header onboarding-business-info-head resp-marg-zero">
              <h3>{t("header")}</h3>
              <p>{t("content")}</p>
            </div>
          </Col>
        </Row>
        <Formik
          initialValues={{
            operational_primary_address:
              profile.data.user_business?.operational_address?.primary_address,
            operational_secondary_address:
              profile.data.user_business?.operational_address
                ?.secondary_address,
            operational_country:
              profile.data.user_business?.operational_address?.country,
            operational_state:
              profile.data.user_business?.operational_address?.state,
            operational_city:
              profile.data.user_business?.operational_address?.city,
            operational_zipcode:
              profile.data.user_business?.operational_address?.zipcode,
            foundation_primary_address:
              profile.data.user_business?.foundation_address?.primary_address,
            foundation_secondary_address:
              profile.data.user_business?.foundation_address?.secondary_address,
            foundation_country:
              profile.data.user_business?.foundation_address?.country,
            foundation_state:
              profile.data.user_business?.foundation_address?.state,
            foundation_city:
              profile.data.user_business?.foundation_address?.city,
            foundation_zipcode:
              profile.data.user_business?.foundation_address?.zipcode,
            tax_id_type: profile.data.user_business?.tax_id_type,
            tax_id_number: profile.data.user_business?.tax_id_number,
            tax_id_issuance_country:
              profile.data.user_business?.tax_id_issuance_country,
            foundation_date: profile.data.user_business.foundation_date.split("-").reverse().join("-"),
            tax_id_date_issued: profile.data.user_business.tax_id_date_issued.split("-").reverse().join("-"),
            tax_id_date_expiry: profile.data.user_business.tax_id_date_expiry.split("-").reverse().join("-"),
            purpose_of_account_opening:
              profile.data.user_business?.purpose_of_account_opening,
            source_of_funds: profile.data.user_business?.source_of_funds,
            // additional_details: profile.data.user_business?.additional_details,
            company_name: profile.data.user_business?.company_name,
            preferred_name: profile.data.user_business?.preferred_name,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {({ setFieldValue, values, touched, errors }) => (
            <FORM
              className="efi-bank-default-form efi-onboarding-form-after-login w-100"
              style={{ display: "flex", flexDirection: "column", gap: "1em" }}
            >
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("company_name.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder={t("company_name.placeholder")}
                      aria-describedby="text-addon"
                      name="company_name"
                      onInput={handleAddressChanges}
                      maxLength={50}
                    />
                    <ErrorMessage
                      name="company_name"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("tax_id_type.name")} <span>*</span>
                    </Form.Label>
                    <Select
                      options={taxIdOptions}
                      styles={customStyles}
                      classNamePrefix="react-select"
                      onChange={(selectedOption) =>
                        setFieldValue("tax_id_type", selectedOption.value)
                      }
                      value={taxIdOptions.find(
                        (option) => option.value === values.tax_id_type
                      )}
                      isSearchable={false}
                      placeholder={t("tax_id_type.placeholder")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="tax_id_type"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("registered_date.name")} <span>*</span>
                    </Form.Label>
                    <div className="single-date-picker">
                      <InputGroup className="mb-0">
                        <DatePicker
                          minDate={new Date("1900-01-01")}
                          maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                          value={values.foundation_date}
                          onChange={(e) =>
                            setFieldValue("foundation_date", e ? `${e.day < 10 ? "0" + e.day : e.day}-${e.month < 10 ? "0" + e.month : e.month}-${e.year}` : "")
                          }
                          placeholder={t("registered_date.placeholder")}
                          width="100%"
                          format="DD-MM-YYYY"
                          id="date-picker-input"
                          editable={false}
                          onOpenPickNewDate={false}
                          popperPlacement="Bottom"
                        />
                        <InputGroup.Text
                          onClick={() => {
                            const input =
                              document.getElementById("date-picker-input");
                            if (input) {
                              input.focus();
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="none"
                            viewBox="0 0 18 18"
                          >
                            <g clipPath="url(#clip0_47_6)">
                              <path
                                fill="#8F9899"
                                d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_47_6">
                                <path fill="#fff" d="M0 0H18V18H0z"></path>
                              </clipPath>
                            </defs>
                          </svg>
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <ErrorMessage
                      component={"div"}
                      name="foundation_date"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("tax_id_date_issued.name")} <span>*</span>
                    </Form.Label>
                    <div className="single-date-picker">
                      <InputGroup className="mb-0">
                        <DatePicker
                          minDate={new Date("1900-01-01")}
                          maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                          value={values.tax_id_date_issued}
                          onChange={(e) =>
                            setFieldValue("tax_id_date_issued", e ? `${e.day < 10 ? "0" + e.day : e.day}-${e.month < 10 ? "0" + e.month : e.month}-${e.year}` : "")
                          }
                          placeholder={t("tax_id_date_issued.placeholder")}
                          width="100%"
                          format="DD-MM-YYYY"
                          id="date-picker-input-1"
                          editable={false}
                          onOpenPickNewDate={false}
                          popperPlacement="Bottom"
                        />
                        <InputGroup.Text
                          onClick={() => {
                            const input = document.getElementById(
                              "date-picker-input-1"
                            );
                            if (input) {
                              input.focus();
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="none"
                            viewBox="0 0 18 18"
                          >
                            <g clipPath="url(#clip0_47_6)">
                              <path
                                fill="#8F9899"
                                d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_47_6">
                                <path fill="#fff" d="M0 0H18V18H0z"></path>
                              </clipPath>
                            </defs>
                          </svg>
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <ErrorMessage
                      component={"div"}
                      name="tax_id_date_issued"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("purpose_of_account_opening.name")} <span>*</span>
                    </Form.Label>
                    <Select
                      options={transactionOptions}
                      styles={customStyles}
                      classNamePrefix="react-select"
                      onChange={(selectedOption) =>
                        setFieldValue(
                          "purpose_of_account_opening",
                          selectedOption.value
                        )
                      }
                      isSearchable={false}
                      value={transactionOptions.find(
                        (option) =>
                          option.value === values.purpose_of_account_opening
                      )}
                      placeholder={t("purpose_of_account_opening.placeholder")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="purpose_of_account_opening"
                      className="errorMsg"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("preferred_name.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder={t("preferred_name.placeholder")}
                      aria-describedby="text-addon"
                      name="preferred_name"
                      onInput={handleAddressChanges}
                      maxLength={20}
                    />
                    <ErrorMessage
                      name="preferred_name"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("tax_id_number.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      className="form-control"
                      type={values.tax_id_type == "tin" ? "text" : "number"}
                      placeholder={t("tax_id_number.placeholder")}
                      aria-describedby="text-addon"
                      name="tax_id_number"
                    />
                    <ErrorMessage
                      name="tax_id_number"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("tax_id_issuance_country.name")} <span>*</span>
                    </Form.Label>
                    <Select
                      options={nationalitiesOptions}
                      styles={customStyles}
                      classNamePrefix="react-select"
                      onChange={(selectedOption) =>
                        setFieldValue(
                          "tax_id_issuance_country",
                          // iso.whereAlpha2(selectedOption.value).alpha3
                          selectedOption.value
                        )
                      }
                      isSearchable
                      value={countryOptions.find((option) =>
                        option.value === values.tax_id_issuance_country
                          // ? iso.whereAlpha3(values.tax_id_issuance_country)
                          //   ?.alpha2
                          ? values.tax_id_issuance_country
                          : ""
                      )}
                      placeholder={t("tax_id_issuance_country.placeholder")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="tax_id_issuance_country"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("tax_id_date_expiry.name")} <span>*</span>
                    </Form.Label>
                    <div className="single-date-picker">
                      <InputGroup className="mb-0">
                        <DatePicker
                          minDate={new Date()}
                          // maxDate={new Date()}
                          value={values.tax_id_date_expiry}
                          onChange={(e) =>
                            setFieldValue("tax_id_date_expiry", e ? `${e.day < 10 ? "0" + e.day : e.day}-${e.month < 10 ? "0" + e.month : e.month}-${e.year}` : "")
                          }
                          placeholder={t("tax_id_date_expiry.placeholder")}
                          width="100%"
                          format="DD-MM-YYYY"
                          id="date-picker-input-2"
                          popperPlacement="Bottom"
                        />
                        <InputGroup.Text
                          onClick={() => {
                            const input = document.getElementById(
                              "date-picker-input-2"
                            );
                            if (input) {
                              input.focus();
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="none"
                            viewBox="0 0 18 18"
                          >
                            <g clipPath="url(#clip0_47_6)">
                              <path
                                fill="#8F9899"
                                d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_47_6">
                                <path fill="#fff" d="M0 0H18V18H0z"></path>
                              </clipPath>
                            </defs>
                          </svg>
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <ErrorMessage
                      component={"div"}
                      name="tax_id_date_expiry"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("source_of_funds.name")} <span>*</span>
                    </Form.Label>
                    <Select
                      options={fundingOptions}
                      styles={customStyles}
                      classNamePrefix="react-select"
                      onChange={(selectedOption) =>
                        setFieldValue("source_of_funds", selectedOption.value)
                      }
                      value={fundingOptions.find(
                        (option) => option.value === values.source_of_funds
                      )}
                      placeholder={t("source_of_funds.placeholder")}
                      isSearchable={false}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="source_of_funds"
                      className="errorMsg"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={6}>
                  <div className="onboarding-auth-header resp-marg-zero-1">
                    <h3> {t("registered_address")}</h3>
                  </div>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("registered_address_1.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder={t("registered_address_1.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="operational_primary_address"
                      onInput={handleAddressChanges}
                      maxLength={30}
                    />
                    <ErrorMessage
                      name="operational_primary_address"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("registered_address_2.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("registered_address_2.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="operational_secondary_address"
                      onInput={handleAddressChanges}
                      maxLength={30}
                    />
                    <ErrorMessage
                      name="operational_secondary_address"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("registered_country.name")} <span>*</span>
                    </Form.Label>
                    <Select
                      options={countryOptions}
                      styles={customStyles}
                      classNamePrefix="react-select"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "operational_country",
                          selectedOption.value
                        );
                        handleAddressChanges();
                      }}
                      value={countryOptions.find(
                        (option) => option.value === values.operational_country
                      )}
                      isSearchable
                      placeholder={t("registered_country.placeholder")}
                    />
                    <ErrorMessage
                      name="operational_country"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("registered_state.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("registered_state.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="operational_state"
                      onInput={handleAddressChanges}
                      maxLength={20}
                    />
                    <ErrorMessage
                      name="operational_state"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("registered_city.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("registered_city.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="operational_city"
                      onInput={handleAddressChanges}
                      maxLength={20}
                    />
                    <ErrorMessage
                      name="operational_city"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("registered_pincode.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("registered_pincode.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="operational_zipcode"
                      onInput={handleAddressChanges}
                      maxLength={7}
                    />
                    <ErrorMessage
                      name="operational_zipcode"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <div className="onboarding-auth-header d-flex align-items-center justify-content-between">
                    <h3>{t("operational_address")}</h3>
                    <Checkbox
                      checked={deliverySameAsBilling}
                      disabled={deliverySameAsBilling}
                      onChange={handleCheckboxChange}
                      icon={
                        <Image
                          src={window.location.origin + "/img/tick-mark.svg"}
                          className="tick-mark-checkbox"
                        />
                      }
                    >
                      {t("same_as_operational_addres")}
                    </Checkbox>
                  </div>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("operational_address_1.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("operational_address_1.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="foundation_primary_address"
                      onInput={handleAddressChanges}
                      maxLength={30}
                    />
                    <ErrorMessage
                      name="foundation_primary_address"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("operational_address_2.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("operational_address_2.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="foundation_secondary_address"
                      onInput={handleAddressChanges}
                      maxLength={30}
                    />
                    <ErrorMessage
                      name="foundation_secondary_address"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("operational_country.name")} <span>*</span>
                    </Form.Label>
                    <Select
                      options={countryOptions}
                      styles={customStyles}
                      classNamePrefix="react-select"
                      onChange={(selectedOption) => {
                        setFieldValue(
                          "foundation_country",
                          selectedOption.value
                        );
                        handleAddressChanges();
                      }}
                      value={countryOptions.find(
                        (option) => option.value === values.foundation_country
                      )}
                      isSearchable
                      placeholder={t("operational_country.placeholder")}
                    />
                    <ErrorMessage
                      name="foundation_country"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("operational_state.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("operational_state.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="foundation_state"
                      onInput={handleAddressChanges}
                      maxLength={20}
                    />
                    <ErrorMessage
                      name="foundation_state"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("operational_city.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("operational_city.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="foundation_city"
                      onInput={handleAddressChanges}
                      maxLength={20}
                    />
                    <ErrorMessage
                      name="foundation_city"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("operational_pincode.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("operational_pincode.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="foundation_zipcode"
                      onInput={handleAddressChanges}
                      maxLength={7}
                    />
                    <ErrorMessage
                      name="foundation_zipcode"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>

                </Col>
              </Row>
              <div className="onboarding-auth-footer">
                <div className="onboarding-auth-footer-skip">
                  <Button
                    className="efi-overlay-btn"
                    onClick={() => props.setStep(props.step - 1)}
                    disabled={businessInfoUpdate.buttonDisable}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();  // Prevent the form submission on Enter key
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#1b3d86"
                        d="M22 11H4.414l5.293-5.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 000-2z"
                        data-original="#000000"
                      ></path>
                    </svg>
                    <span>{t("back")}</span>
                  </Button>
                </div>
                <div className="space-tow">
                  <Button
                    className="efi-primary-btn"
                    type="submit"
                    disabled={businessInfoUpdate.buttonDisable}
                  // onClick={() => props.setStep(3)}
                  >
                    {businessInfoUpdate.buttonDisable ? (
                      <ButtonLoader varient="black" />
                    ) : (
                      t("continue")
                    )}
                  </Button>
                </div>
              </div>
            </FORM>
          )}
        </Formik>
      </div>
    </>
  );
};

export default BusinessInformation;
