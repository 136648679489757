import React, { useState, useEffect, useRef } from "react";
import { Form, Modal, Button, InputGroup, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { allContactsListStart } from "../../store/slices/AccountsSlice";
import { ButtonLoader } from "../Helper/Loader";
import { transferMoneyStart } from "../../store/slices/PaymentSlice";
import OtpVerification from "./OtpVerification";

const CardSendMoneyModal = (props) => {
  const t = useTranslation("cards.card_send_money");
  const dispatch = useDispatch();
  const transferMoney = useSelector((state) => state.payment.transferMoney);
  const allContactsList = useSelector((state) => state.accounts.allContactsList);
  const profile = useSelector((state) => state.admin.profile);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#585c5f",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#1b3d86!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#878E96",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? '#1b3d86' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      ":hover": {
              backgroundColor: "#1b3d86",
              color: "#fff"
            },
  }),
  };
  const [contactOptions, setContactOptions] = useState([]);
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError(t("amount.invalid_type"))
      .min(0.1, t("amount.at_least"))
      .required(t("amount.required")),
    m_contact_unique_id: Yup.string().required(t("contact.required")),
  });

  useEffect(() => {
    dispatch(allContactsListStart());
  }, []);

  const handleSubmit = (values) => {
    if(profile.data.tfa_status) {
      props.setShowOTPVerifyModal({
        ...values,
        action: 5,
      })
      props.onHide();
    } else dispatch(transferMoneyStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !transferMoney.loading &&
      Object.keys(transferMoney.data).length > 0
    ) {
      props.onHide();
    }
    setSkipRender(false);
  }, [transferMoney]);

  useEffect(() => {
    if (
      !skipRender &&
      !allContactsList.loading &&
      Object.keys(allContactsList.data).length > 0 && allContactsList.data.m_contacts.length > 0
    ) {
      setContactOptions(
        allContactsList.data.m_contacts.map((item) => ({
          label: item.name,
          value: item.unique_id,
        }))
      );
    }
    setSkipRender(false);
  }, [allContactsList]);

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="card-sendmoney-modal"
        backdrop="static"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="modal-card-title">
            <h3 className="change-password-heading">{t("heading")}</h3>
          </div>
          <Formik
            initialValues={{
              amount: "",
              m_contact_unique_id: "",
              description: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values, touched, errors }) => (
              <FORM className="efi-bank-default-form w-100">
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label w-100">
                        {t("amount.name")}
                      </Form.Label>
                      <Field
                        className="form-control"
                        type="number"
                        placeholder={t("amount.placeholder")}
                        name="amount"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="amount"
                        className="errorMsg"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label w-100">
                        {t("contact.name")}
                      </Form.Label>
                      <Select
                        options={contactOptions}
                        styles={customStyles}
                        disabled={allContactsList.buttonDisable}
                        classNamePrefix="react-select"
                        onChange={(selectedOption) =>
                          setFieldValue(
                            "m_contact_unique_id",
                            selectedOption.value
                          )
                        }
                        value={
                          contactOptions.length > 0 &&
                          contactOptions.find(
                            (option) =>
                              option.value === values.m_contact_unique_id
                          )
                        }
                        placeholder="Select Recipient"
                        isSearchable= {false}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="m_contact_unique_id"
                        className="errorMsg"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label w-100">
                        {t("description.name")}
                      </Form.Label>
                      <Field
                        className="form-control"
                        as="textarea"
                        type="text"
                        placeholder={t("description.placeholder")}
                        name="description"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="description"
                        className="errorMsg"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="onboarding-auth-footer">
                  <Button
                    className="efi-primary-btn"
                    type="submit"
                    disabled={transferMoney.buttonDisable}
                    style={{ width: "100%" }}
                  >
                    {transferMoney.buttonDisable ? (
                      <ButtonLoader varient="black" />
                    ) : (
                      t("btn_text")
                    )}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CardSendMoneyModal;
