import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Image, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { verifyKycStart } from "../../../store/slices/AdminSlice";
import { useTranslation } from "react-multi-lang";

const OnboardingKyc = (props) => {
  const t = useTranslation("onboarding.onboarding_form.onboarding_kyc");
  const navigate = useNavigate();
  const businessInfoUpdate = useSelector(
    (state) => state.business.businessInfoUpdate
  );
  const dispatch = useDispatch();
  const kycVerify = useSelector((state) => state.admin.kycVerify);
  const [skipRender, setSkipRender] = useState(true);
  const profile = useSelector((state) => state.admin.profile);

  useEffect(() => {
    if (
      !skipRender &&
      !kycVerify.loading &&
      Object.keys(kycVerify.data).length > 0
    ) {
      if (kycVerify.data.link.length > 0) {
        window.open(kycVerify.data.link, "_blank");
      } else {
        props.setStep(props.step + 1);
      }
    }
    setSkipRender(false);
  }, [kycVerify]);

  return (
    <>
      <div className="onboarding-final-frame">
        <Row className="justify-content-end">
          <Col lg={3}>
            <div className="text-end">
              <Button
                className="efi-overlay-btn w-100"
                onClick={() => navigate("/")}
                disabled={businessInfoUpdate.buttonDisable}
              >
                {t("skip_onboarding")}
              </Button>
            </div>
          </Col>
        </Row>
        <div className="onboarding-inner-area">
          <div className="welcome-onboarding-img-sec">
            <Image
              src={
                window.location.origin +
                "/img/feature/onboarding/illustration-four.svg"
              }
              className="illustration-avater"
            />
          </div>
          <div className="welcome-onboarding-info-sec">
            <h4>{t("heading")} </h4>
            <p>{t("content")}</p>
            {/* <Button
              disabled={kycVerify.buttonDisable}
              onClick={() => dispatch(verifyKycStart())}
              className="default-primary-btn"
            >
              Verify Kyc
            </Button> */}
          </div>
        </div>
        <div className="">
            <h5 className="text-start">{t("checklist")}</h5>
            <p className="text-start">1.{t("passport_national_id")}</p>
            <p className="text-start">2{t("bill_statement")}</p>
            <p className="text-start">3.{t("verify_consent")} </p>
          </div>
        <div className="onboarding-auth-footer">
          <div className="onboarding-auth-footer-skip">
            <Button
              className="efi-overlay-btn"
              type="submit"
              onClick={() => navigate("/settings")}
            >
              <span>{t("edit_onboarding")}</span>
            </Button>
          </div>
          <div className="space-tow">
            {profile.data.service_type == 1 ? (
              profile.data.mastercard_kyc_status == 3 ? (
                <Button
                  onClick={() => props.setStep(props.step + 1)}
                  className="efi-primary-btn"
                >
                  {t("continue")}
                </Button>
              ) : (
                <Button
                  className="efi-primary-btn"
                  disabled={kycVerify.buttonDisable}
                  onClick={() => dispatch(verifyKycStart())}
                >
                  {t("verify_kyc")}
                </Button>
              )
            ) : profile.data.iban_kyc_status == 3 ? (
              <Button
                onClick={() => props.setStep(props.step + 1)}
                className="efi-primary-btn"
              >
                {t("continue")}
              </Button>
            ) : (
              <Button
                className="efi-primary-btn"
                disabled={kycVerify.buttonDisable}
                onClick={() => dispatch(verifyKycStart())}
              >
                {t("verify_kyc")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default OnboardingKyc;
