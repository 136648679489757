import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Form, Row, Col, Dropdown } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import ViewTransactionModal from "../Payment/ViewTransactionHistoryModal";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { DateObject } from "react-multi-date-picker";
import Footer from "react-multi-date-picker/plugins/range_picker_footer";
import { useDispatch, useSelector } from "react-redux";
import "../Payment/payment.css";
import {
  accountStatementsDownloadStart,
  accountStatementsListStart,
  updateBeneficiaryStatusStart,
} from "../../store/slices/BeneficiariesSlice";
import { Field, Form as FORM, Formik } from "formik";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import * as Yup from "yup";
import Select from "react-select";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../Helper/NotificationMessage";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import Pagination from "../Helper/Pagination";
import StatementModal from "./StatementModal";
import { ButtonLoader, ButtonLoaderDark } from "../Helper/Loader";
import { Tooltip } from 'react-tooltip';

const BalanceStatement = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const calenderRefs = useRef(null);
  const formRef = useRef(null);
  const [searchParams] = useSearchParams();
  const t = useTranslation("balance_statement");

  const accountStatementsList = useSelector((state) => state.beneficiaries.accountStatementsList);
  const accountStatementsDownload = useSelector((state) => state.beneficiaries.accountStatementsDownload);
  const profile = useSelector((state) => state.admin.profile);
  const accountsStatements = useSelector((state) => state.accounts.accountsStatements);

  // const [startDate, setStartDate] = useState(new Date("2024/04/08"));
  // const [endDate, setEndDate] = useState(new Date("2024/02/10"));
  const [skipRender, setSkipRender] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [typeStatus, setTypeStatus] = useState(false);

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const day = ("0" + d.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    status: "",
    from_date: formatDate(
      new Date(new Date().setMonth(new Date().getMonth() - 1))
    ),
    to_date: formatDate(new Date()),
    skip: 0,
  });
  const [close, setClose] = useState(false);
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [dateValues, setDateValues] = useState({
    from_date: "",
    to_date: "",
  });
  const [value, setValue] = useState([null, null]);
  const [statementModal, setStatementModal] = useState(false);

  const type_options = [
    { value: "1", label: "Initiated" },
    { value: "2", label: "Processing" },
    { value: "3", label: "Completed" },
    { value: "4", label: "Failed" },
  ];

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      if (profile.data.onboarding >= 4) {
        dispatch(
          accountStatementsListStart({
            ...filter,
            skip: 30 * (page - 1),
            take: 30,
          })
        );
      } else {
        getErrorNotificationMessage("Please complete onboarding to continue!")
      }
    }
  }, [profile, filter, page]);


  const getStatusColor = (type) => {
    switch (type) {
      case "credit":
        return "#197E23";
      default:
        return "#FF6A6A";
    }
  };

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    navigate(`/beneficiary-transactions?${params}page=${selected + 1}`);
  };

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
      setFilter({
        ...filter,
        search_key: searchParams.get("search_key")
          ? searchParams.get("search_key")
          : "",
        from_date: dateValues.from_date,
        to_date: dateValues.to_date,
      });
    }
    setSkipRender(false);
  }, [searchParams.get("page"), searchParams.get("search_key")]);

  useEffect(() => {
    if (
      !skipRender &&
      !accountsStatements.buttonDisable &&
      Object.keys(accountsStatements.data).length > 0
    ) {
      accountsStatements.data?.url &&
        window.open(accountsStatements.data.url, "_blank");
    }
    setSkipRender(false);
  }, [accountsStatements]);

  const validationSchema = Yup.object().shape({
    search_key: Yup.string()
      .required(t("required"))
      .matches(/^(?=\S*$)/, t("white_space")),
  });

  const handleDateChange = (values) => {
    if (values) {
      setValue(values);
      formRef?.current?.setFieldValue(
        "from_date",
        values && values.length > 0
          ? JSON.stringify(new Date(values[0])).slice(1, 11)
          : ""
      );
      formRef?.current?.setFieldValue(
        "to_date",
        values && values.length > 1
          ? JSON.stringify(new Date(values[1])).slice(1, 11)
          : ""
      );
      setDateValues({
        from_date:
          values.length > 0
            ? JSON.stringify(new Date(values[0])).slice(1, 11)
            : "",
        to_date:
          values.length > 1
            ? JSON.stringify(new Date(values[1])).slice(1, 11)
            : "",
      });
    } else {
      setDateValues({
        from_date: "",
        to_date: "",
      });
      formRef?.current?.setFieldValue("from_date", "");
      formRef?.current?.setFieldValue("to_date", "");
      calenderRefs.current.closeCalendar();
      setFilter({
        ...filter,
        skip: 30 * (page - 1),
        take: 30,
        from_date: "",
        to_date: "",
      });
    }
  };

  useEffect(()=> {
    if(!skipRender && !accountStatementsDownload.buttonDisable && Object.keys(accountStatementsDownload.data).length > 0){
      window.open(accountStatementsDownload.data.url, "blank")
    }
    setSkipRender(false);
  }, [accountStatementsDownload])

  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="common-full-card">
          {/* <div className="balance-statement-box">
            <Row className="justify-content-center">
              <Col lg={6}>
                <div className="balance-statement-card">
                  <div className="balance-label">Currency</div>
                  <Select
                    options={type_options}
                    className="select-payout"
                    styles={customStyles}
                    isSearchable={false}
                    isClearable
                    value={typeStatus || null}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFilter({
                          ...filter,
                          status: selectedOption.value,
                        });
                        setTypeStatus(selectedOption);
                        navigate(
                          filter.search_key
                            ? `/beneficiary-transactions?search_key=${filter.search_key}&status=${selectedOption.value}`
                            : `/beneficiary-transactions?status=${selectedOption.value}`
                        );
                      } else {
                        const newFilter = { ...filter };
                        delete newFilter.status;
                        setFilter(newFilter);
                        navigate(
                          filter.search_key
                            ? `/beneficiary-transactions?search_key=${filter.search_key}`
                            : "/beneficiary-transactions"
                        );
                        setTypeStatus(null);
                      }
                    }}
                    placeholder={
                      <div className="placeholder-flex">{t("status")}</div>
                    }
                  />
                  <div className="bal-single-space"></div>
                  <div className="balance-statement-card-info">
                    <h3>
                      Balance: <span>23,234 $</span>
                    </h3>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="single-space"></div>{" "} */}
          <div className="efi-feature-header-frame layout-between align-items-center">
            <div className="efi-feature-transation-title balance-sheet-flex">
              <h2>
                {t("heading")}
                <span className="ml-3">
                  {accountStatementsList.data?.m_account_statements?.length || 0}
                </span>
              </h2>
              <div className="important-card">
                <div className="important-icons">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="#1b3d86"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10z"
                    ></path>
                    <path
                      stroke="#1b3d86"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M12 8v5M11.995 16h.009"
                      opacity="0.8"
                    ></path>
                  </svg>
                </div>
                <div className="important-info">
                  <p>{t("current_entries")}: {filter.from_date.split("-").reverse().join("-") + ` ${t("to")} ` + filter.to_date.split("-").reverse().join("-")}</p>
                </div>
              </div>
            </div>
            <div className="efi-feature-transation-search-frame">
              
              {/* <Select
                options={type_options}
                className="select-payout"
                styles={customStyles}
                isSearchable={false}
                isClearable
                value={typeStatus || null}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setFilter({
                      ...filter,
                      status: selectedOption.value,
                    });
                    setTypeStatus(selectedOption);
                    navigate(
                      filter.search_key
                        ? `/beneficiary-transactions?search_key=${filter.search_key}&status=${selectedOption.value}`
                        : `/beneficiary-transactions?status=${selectedOption.value}`
                    );
                  } else {
                    const newFilter = { ...filter };
                    delete newFilter.status;
                    setFilter(newFilter);
                    navigate(
                      filter.search_key
                        ? `/beneficiary-transactions?search_key=${filter.search_key}`
                        : "/beneficiary-transactions"
                    );
                    setTypeStatus(null);
                  }
                }}
                placeholder={
                  <div className="placeholder-flex">{t("status")}</div>
                }
              /> */}
              {profile.data.onboarding >= 4 ? <div className="header-search">
                <Formik
                  initialValues={{
                    search_key: searchParams.get("search_key")
                      ? searchParams.get("search_key")
                      : "",
                    sort_by: searchParams.get("sort_by")
                      ? searchParams.get("sort_by")
                      : filter.sort_by,
                    from_date: formatDate(
                      new Date(new Date().setMonth(new Date().getMonth() - 1))
                    ),
                    to_date: formatDate(new Date()),
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(val) => {
                    setFilter({
                      ...filter,
                      search_key: val.search_key,
                      from_date: dateValues.from_date,
                      to_date: dateValues.to_date,
                    });

                    navigate(
                      searchParams.get("sort_by")
                        ? `/beneficiary-transactions?search_key=${val.search_key
                        }&sort_by=${searchParams.get("sort_by")}`
                        : `/beneficiary-transactions?search_key=${val.search_key}`,
                      { state: { value: value } }
                    );
                  }}
                  innerRef={formRef}
                >
                  {({ values, setFieldValue, resetForm }) => (
                    <FORM className="form">
                      <div className="efi-transation-search">
                        <InputGroup
                          className={`d-flex date-sec mb-0 efi-data-input ${close ? "date-close" : ""
                            }`}
                        >
                          <DatePicker
                            value={value}
                            style={{ padding: "1em" }}
                            onChange={(date) => {
                              handleDateChange(date);
                            }}
                            range
                            numberOfMonths={2}
                            ref={calenderRefs}
                            format="DD-MM-YYYY"
                            maxDate={
                              new Date(new Date().setHours(23, 59, 59, 999))
                            }
                            id="date-picker-input"
                            placeholder={t("date.date_range")}
                            // render={<InputIcon/>}
                            editable={false}
                            onOpenPickNewDate={false}
                            disabled={accountStatementsList.buttonDisable}
                          >
                            <div
                              className="d-flex justify-content-end gap-2"
                              style={{ padding: "1em" }}
                            >
                              <Button
                                className="efi-datePicker-btn-cancel"
                                onClick={() => {
                                  calenderRefs.current.closeCalendar();
                                  setDateValues({
                                    from_date: "",
                                    to_date: "",
                                  });
                                  setValue([null, null]);
                                  setClose(false);
                                  // setFilter({
                                  //   ...filter,
                                  //   from_date: "",
                                  //   to_date: "",
                                  // });
                                }}
                              >
                                {t("close")}
                              </Button>
                              <Button
                                className="efi-datePicker-btn"
                                onClick={() => {
                                  if (values.from_date && values.to_date) {
                                    setFilter({
                                      ...filter,
                                      from_date: dateValues.from_date.toString(),
                                      to_date: dateValues.to_date.toString(),
                                    });
                                    calenderRefs.current.closeCalendar();
                                    setClose(true);
                                  } else
                                    getErrorNotificationMessage(
                                      t("date.select_date")
                                    );
                                }}
                              >
                                {t("apply")}
                              </Button>
                            </div>
                          </DatePicker>
                          {close ? (
                            <>
                              <button
                                type="button"
                                className="search-btn date-cancel"
                                onClick={() => {
                                  // setValue([null , new Date()]);
                                  setDateValues({
                                    from_date: "",
                                    to_date: "",
                                  });
                                  setValue([null, null]);
                                  setClose(false);
                                  setFilter({
                                    ...filter,
                                    from_date: formatDate(
                                      new Date(
                                        new Date().setMonth(
                                          new Date().getMonth() - 1
                                        )
                                      )
                                    ),
                                    to_date: formatDate(new Date()),
                                  });
                                }}
                              >
                                <svg
                                  height="24"
                                  width="24"
                                  viewBox="0 0 20 20"
                                  aria-hidden="true"
                                  focusable="false"
                                  class="css-tj5bde-Svg"
                                >
                                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                </svg>
                              </button>
                            </>
                          ) : (
                            <InputGroup.Text
                              onClick={() => {
                                const input =
                                  document.getElementById("date-picker-input");
                                if (input) {
                                  input.focus();
                                }
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                fill="none"
                                viewBox="0 0 18 18"
                              >
                                <g clipPath="url(#clip0_47_6)">
                                  <path
                                    fill="#8F9899"
                                    d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_47_6">
                                    <path fill="#fff" d="M0 0H18V18H0z"></path>
                                  </clipPath>
                                </defs>
                              </svg>
                            </InputGroup.Text>
                          )}
                        </InputGroup>
                        <Dropdown className="download-dropdown">
                          <Dropdown.Toggle id="dropdown-basic" disabled={accountStatementsList.buttonDisable || accountStatementsDownload.buttonDisable || !(accountStatementsList.data?.m_account_statements?.length > 0)}>
                            {accountStatementsDownload.buttonDisable ? (
                            <ButtonLoaderDark />
                          ) : (
                            <svg
                              style={{ display: "flex", margin: "auto" }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="icon icon-tabler icons-tabler-outline icon-tabler-download"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                              <path d="M7 11l5 5l5 -5" />
                              <path d="M12 4l0 12" />
                            </svg>
                          )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={()=> dispatch(accountStatementsDownloadStart({
                                ...filter,
                                type : 1,
                              }))}>PDF</Dropdown.Item>
                              <Dropdown.Item onClick={()=> dispatch(accountStatementsDownloadStart({
                                ...filter,
                                type : 2,
                              }))}>Excel</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      {/* <div className="efi-transation-search">
                        <InputGroup className="mb-0">
                          <Field
                            // placeholder={t("search_placeholder")}
                            placeholder={t("search")}
                            type="text"
                            className="form-control trans-form-control"
                            name="search_key"
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                formRef.current.handleSubmit();
                              }
                            }}
                          />
                          {searchParams.get("search_key") ? (
                            <InputGroup.Text id="basic-addon1">
                              <Button
                                className="search-btn"
                                onClick={() => {
                                  setFieldValue("search_key", "");
                                  setFilter({
                                    ...filter,
                                    search_key: "",
                                    from_date: dateValues.from_date,
                                    to_date: dateValues.to_date,
                                  });
                                  navigate(
                                    searchParams.get("sort_by")
                                      ? `/beneficiary-transactions?sort_by=${searchParams.get(
                                          "sort_by"
                                        )}`
                                      : `/beneficiary-transactions`
                                  );
                                }}
                              >
                                <svg
                                  height="20"
                                  width="20"
                                  viewBox="0 0 20 20"
                                  aria-hidden="true"
                                  focusable="false"
                                  class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                                >
                                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                </svg>
                              </Button>
                            </InputGroup.Text>
                          ) : (
                            <InputGroup.Text id="basic-addon1">
                              <Button className="search-btn" type="submit">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#333"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  // class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                                  <path d="M21 21l-6 -6" />
                                </svg>
                              </Button>
                            </InputGroup.Text>
                          )}
                        </InputGroup>
                      </div> */}
                    </FORM>
                  )}
                </Formik>
              </div> : null}
              {/* <Button
                className="download-btn"
                type="button"
                onClick={()=> dispatch(accountStatementsDownloadStart({
                  ...filter,
                  skip: 30 * (page - 1),
                  take: 30,
                }))}
                disabled={
                  accountStatementsList.buttonDisable || accountStatementsDownload.buttonDisable || !(accountStatementsList.data?.m_account_statements?.length > 0)
                }>
                {accountStatementsDownload.buttonDisable ? (
                  <ButtonLoaderDark />
                ) : (
                  <svg
                    style={{ display: "flex", margin: "auto" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-download"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                    <path d="M7 11l5 5l5 -5" />
                    <path d="M12 4l0 12" />
                  </svg>
                )}
              </Button> */}
            </div>
          </div>
          {accountStatementsList.buttonDisable ? (
            <>
              {[...Array(6)].map((_, key) => (
                <Skeleton key={key} className="mb-3" height={80} width={"100%"} />
              ))}
            </>
          ) : Object.keys(accountStatementsList.data).length > 0 &&
            accountStatementsList.data.m_account_statements.length > 0 ? (
            <>
              <Table className="common-table" responsive>
                <thead className="common-table-header">
                  <tr>
                    {/* <th>S. No.</th> */}
                    {/* <th>{t("remarks")}</th> */}
                    <th>{t("statement_date")}</th>
                    <th>{t("ref_id")}</th>
                    <th>{t("debit")}</th>
                    <th>{t("credit")}</th>
                    <th>{t("running_balance")}</th>
                    <th>{t("actions")}</th>
                  </tr>
                </thead>
                <tbody className="common-table-body">
                  {accountStatementsList.data.m_account_statements.map(
                    (item, key) => (
                      <tr key={key}>
                        {/* <td>{key + 1}</td> */}
                        {/* <td>{Transfer to donAccount(#dsdc303)}</td> */}
                        <td>{item.transaction_date}</td>
                        <td>{item.transaction_id}</td>
                        <td style={{color: item.type == "debit" ? getStatusColor(item.type) : "#000"}}>
                          {item.type == "debit" ? `$${item.amount}` : "-"}
                        </td>
                        <td style={{color: item.type == "credit" ? getStatusColor(item.type) : "#000"}}>
                          {item.type == "credit" ? `$${item.amount}` : "-"}
                        </td>
                        <td>${item.balance}</td>
                        { /*
                        <td
                          className="view-beneficiary-btn"
                          style={{
                            display: "flex",
                            gap: "1em",
                            justifyContent: "center",
                          }}
                        >
                          <Link
                            className="efi-overlay-btn"
                            to="#"
                            onClick={() => setStatementModal(item)}
                          >
                            {t("view")}
                          </Button>
                        </td> */}
                        <td className="delete-contact-btn-sec">
                          {item.description.length > 0 ? 
                          <Link
                            // className="efi-overlay-btn"
                            onClick={() => setStatementModal(item)}
                            className="view efi-overlay-btn btn-action"
                            >
                               <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlSpace="preserve"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <g fill="#1b3d86">
                              <path
                                d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.91 4.91 0 0 0 0 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.91 4.91 0 0 0 0-5.162m-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.92 2.92 0 0 1 0-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.92 2.92 0 0 1 0 3.068"
                                data-original="#000000"
                              ></path>
                              <path
                                d="M12 7a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5m0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3"
                                data-original="#000000"
                              ></path>
                            </g>
                          </svg>
                            {/* {t("view")} */}
                          </Link> : null}
                          <Tooltip anchorSelect=".view" place="bottom" className="tooltip-element-box">
                          {t("view")}
                        </Tooltip>
                      </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
              {accountStatementsList.data.total_beneficiary_transactions >
                30 ? (
                <div className="new-billings-pagination-sec">
                  <Pagination
                    page={page}
                    pageCount={Math.ceil(
                      accountStatementsList.data
                        .total_beneficiary_transactions / 30
                    )}
                    handlePageClick={handlePageClick}
                  />
                </div>
              ) : null}
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
      {statementModal ? (
        <StatementModal
          show={statementModal}
          onHide={() => setStatementModal(false)}
        />
      ) : null}
    </>
  );
};

export default BalanceStatement;
