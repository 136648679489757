import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Form, Row, Col } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import { Field, Form as FORM, Formik, ErrorMessage } from "formik";
import Select from "react-select";
import { Radio } from "pretty-checkbox-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import * as Yup from "yup";
import { countries } from "countries-list";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
  getCountries,
} from "react-phone-number-input";
import { useTranslation } from "react-multi-lang";
import iso from "iso-3166-1";
import { useDispatch, useSelector } from "react-redux";
import {
  senderCreateStart,
  senderUpdateStart,
} from "../../store/slices/SendersSlice";
import { ButtonLoader } from "../Helper/Loader";
import { Link, useNavigate } from "react-router-dom";
import {
  countriesListStart,
  mobileCodeListStart,
} from "../../store/slices/AdminSlice";
import Skeleton from "react-loading-skeleton";

const EditSender = () => {

  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("single_sender.sender_create");

  const senderUpdate = useSelector((state) => state.senders.senderUpdate);
  const profile = useSelector((state) => state.admin.profile);
  const senderDetails = useSelector((state) => state.senders.senderDetails);
  const countriesList = useSelector((state) => state.admin.countriesList);
  const mobileCodeList = useSelector((state) => state.admin.mobileCodeList);

  const [senderType, setSenderType] = useState(1);
  const [skipRender, setSkipRender] = useState(true);
  const [countryList, setCountryList] = useState([]);
  const [filteredMobileCode, setMobileCode] = useState([]);
  const [country, setCountry] = useState(null);

  useEffect(() => {
    if (
      !skipRender &&
      !countriesList.buttonDisable &&
      Object.keys(countriesList.data).length > 0
    ) {
      if (countriesList.data.total_countries > 0) {
        setCountryList(
          Object.keys(countries)
            .filter((country) => countriesList.data.countries.includes(country))
            .map((alpha2) => ({
              value: alpha2,
              label: countries[alpha2].name,
            }))
        );
        setCountry(
          Object.keys(countries)
            .filter((country) => countriesList.data.countries.includes(country))
            .map((alpha2) => ({
              value: alpha2,
              label: countries[alpha2].name,
            }))
            ?.find((option) => option.value == senderDetails.country)
        );
      }
    }
    setSkipRender(false);
  }, [countriesList]);

  useEffect(() => {
    if (
      !skipRender &&
      !mobileCodeList.buttonDisable &&
      Object.keys(mobileCodeList.data).length > 0
    ) {
      if (mobileCodeList.data.mobile_country_codes.length > 0) {
        setMobileCode(mobileCodeList.data.mobile_country_codes);
      }
    }
    setSkipRender(false);
  }, [mobileCodeList]);

  useEffect(() => {
    dispatch(countriesListStart());
    dispatch(mobileCodeListStart());
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      cursor: "pointer",
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid  #DDE1E6 !important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "1em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.85em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      color: "#757575",
      fontSize: "0.85em",
      fontWeight: "400",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#1b3d86!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#1b3d86!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#757575",
      },
    }),
    // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   return {
    //     ...styles,
    //     backgroundColor: isSelected ? "#f7f7f7" : "#fff",
    //     color: "#000",
    //   };
    // },
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#1b3d86" : "#fff",
      color: state.isFocused || state.isSelected ? "#fff" : "#000",
      ":hover": {
        backgroundColor: "#1b3d86",
        color: "#fff",
      },
    }),
  };

  const validationSchema = Yup.object().shape({
    unique_id: Yup.string()
      .required(t("required"))
      .min(1, t("min_invalid"))
      // .matches(/^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/, "Invalid Unique ID")
      .matches(/^[a-zA-Z0-9_-]+$/, t("invalid"))
      .trim(),
    first_name: Yup.string()
      .required(t("required"))
      .min(1, t("min_invalid"))
      .matches(/^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/, t("first_name.invalid"))
      .trim(),
    middle_name: Yup.string()
      .min(1, t("min_invalid"))
      .matches(/^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/, t("middle_name.invalid"))
      .trim(),
    last_name: Yup.string()
      .required(t("required"))
      .min(1, t("min_invalid"))
      .matches(/^\S.*$/, t("required"))
      .matches(/^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/, t("last_name.invalid"))
      .trim(),
    email: Yup.string()
      .email(t("email.invalid"))
      //   .required(t("required"))
      .matches(/^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/, t("invalid"))
      .trim(),
    country: Yup.string().required(t("required")),
    address: Yup.string()
      .required(t("required"))
      .min(1, t("min_invalid"))
      .matches(/^[#.0-9a-zA-Z\s,-_)('‘’‚‛′]+$/, t("invalid")),
    postal_code: Yup.string()
      .strict(true) // ensures that trimming won't happen automatically
      .matches(/^\S+$/, t("no_spaces_are_allowed")) // no spaces allowed
      .required(t("required"))
      .min(1, t("min_invalid")),
    mobile: Yup.string()
      .test("valid-mobile", t("mobile.invalid"), (value) => {
        if (!value) {
          return false;
        }
        return isValidPhoneNumber(value);
      })
      .required(t("required"))
      .min(1, t("min_invalid"))
      .matches(/^(?=.*[0-9])/, t("mobile_number.space_values_not_valid")),
    source_of_funds: Yup.string()
      .min(1, t("min_invalid"))
      .matches(/^\S.*$/, t("required"))
      .matches(
        /^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/,
        t("source_of_funds.invalid")
      )
      .trim(),
  });

  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile_number.invalid"));
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile_number.invalid"));
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("mobile_number.required"));
      return false;
    }
  };

  const handleSubmit = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      dispatch(
        senderUpdateStart({
          ...values,
          mobile: mobile,
          mobile_country_code: countryCode,
          sender_id: senderDetails.id,
        })
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !senderUpdate.loading &&
      Object.keys(senderUpdate.data).length > 0
    ) {
      navigate("/sender-list");
    }
    setSkipRender(false);
  }, [senderUpdate]);

  useEffect(() => {
    if (Object.keys(senderDetails).length <= 0) {
      navigate("/sender-list");
    }
  });

  return (
    <div className="full-body-card-wrapped">
      {Object.keys(senderDetails).length > 0 && (
        <div className="contact-list-card-area">
          <div className="payment-table-header">
            <Link onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                data-name="Layer 2"
                viewBox="0 0 24 24"
              >
                <path d="M22 11H4.414l5.293-5.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 000-2z"></path>
              </svg>
            </Link>
            <h3>{t("edit_heading")}</h3>
          </div>
          <Formik
            initialValues={{
              type: senderDetails.type,
              unique_id: senderDetails.unique_id,
              first_name: senderDetails.first_name,
              last_name: senderDetails.last_name,
              middle_name: senderDetails.middle_name,
              email: senderDetails.email,
              mobile: `+${senderDetails.mobile_country_code}${senderDetails.mobile}`,
              address: senderDetails.address,
              postal_code: senderDetails.postal_code,
              country: senderDetails.country,
              source_of_funds: senderDetails.source_of_funds,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            innerRef={formRef}
          >
            {({ errors, touched, values, setFieldValue, setValues }) => (
              <FORM className="create-beneficiary-from">
                <Row>
                  <Col md={12} lg={6} xl={6}>
                    <div className="sender-from-box">
                      <Form.Label>
                        {t("sender_type.name")} <span>*</span>
                      </Form.Label>
                      <Col md={12}>
                        <Form.Group className="mb-3">
                          <Radio
                            name="radio"
                            checked={values.type == 1}
                            onClick={() => setFieldValue("type", 1)}
                            disabled
                          >
                            {t("sender_type.individual")}
                          </Radio>
                          <Radio
                            name="radio"
                            checked={values.type == 2}
                            onClick={() => setFieldValue("type", 2)}
                            disabled
                          >
                            {t("sender_type.business")}
                          </Radio>
                        </Form.Group>
                      </Col>
                    </div>
                  </Col>
                  <Col md={12} lg={12} xl={12}>
                    <div className="sender-from-box">
                      <Row>
                        <React.Fragment>
                          <Col md={12} lg={6} xl={6}>
                            <div className="sender-from-box">
                              <Row>
                                <Col sm={12} lg={12} xl={12}>
                                  <Form.Group
                                    controlId="formBasicEmail"
                                    className="mb-3"
                                  >
                                    <Form.Label>
                                      {t("unique_id.name")} <span>*</span>
                                    </Form.Label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name="unique_id"
                                      placeholder={t("unique_id.placeholder")}
                                      maxLength={50}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="unique_id"
                                      className="text-danger"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={12} lg={12} xl={4}>
                                  <Form.Group
                                    controlId="formBasicEmail"
                                    className="mb-3"
                                  >
                                    <Form.Label>
                                      {t("first_name.name")} <span>*</span>
                                    </Form.Label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name="first_name"
                                      placeholder={t("first_name.placeholder")}
                                      maxLength={50}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="first_name"
                                      className="text-danger"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={12} lg={12} xl={4}>
                                  <Form.Group
                                    controlId="formBasicEmail"
                                    className="mb-3"
                                  >
                                    <Form.Label>
                                      {t("middle_name.name")}
                                    </Form.Label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name="middle_name"
                                      placeholder={t("middle_name.placeholder")}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="middle_name"
                                      className="text-danger"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={12} lg={12} xl={4}>
                                  <Form.Group
                                    controlId="formBasicEmail"
                                    className="mb-3"
                                  >
                                    <Form.Label>
                                      {t("last_name.name")} <span>*</span>
                                    </Form.Label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name="last_name"
                                      placeholder={t("last_name.placeholder")}
                                      maxLength={50}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="last_name"
                                      className="text-danger"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={12} lg={12} xl={6}>
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>{t("email.name")}</Form.Label>
                                    <Field
                                      type="email"
                                      className="form-control"
                                      name="email"
                                      placeholder={t("email.placeholder")}
                                      disabled
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="email"
                                      className="text-danger"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={12} lg={12} xl={6}>
                                  <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label>
                                      {t("mobile_number.name")} <span>*</span>
                                    </Form.Label>
                                    {!mobileCodeList.loading ? (
                                      <PhoneInput
                                        defaultCountry={
                                          profile.data.nationality
                                        }
                                        placeholder={t(
                                          "mobile_number.placeholder"
                                        )}
                                        value={values.mobile}
                                        onChange={(value) =>
                                          setFieldValue("mobile", value)
                                        }
                                        onBlur={() =>
                                          formRef.current.submitForm()
                                        }
                                        international
                                        countries={filteredMobileCode}
                                        countryCallingCodeEditable={false}
                                        maxLength={31}
                                        disabled
                                      />
                                    ) : (
                                      <Skeleton width="100%" height={42} />
                                    )}
                                    <ErrorMessage
                                      component={"div"}
                                      name="mobile"
                                      className="text-danger"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col md={12} lg={6} xl={6}>
                            <div className="sender-from-box">
                              <Row>
                                <Col sm={12} lg={12} xl={12}>
                                  <Form.Group
                                    controlId="formBasicEmail"
                                    className="mb-3"
                                  >
                                    <Form.Label>
                                      {t("address.name")} <span>*</span>
                                    </Form.Label>
                                    <Field
                                      className="form-control"
                                      name="address"
                                      // as="textarea"
                                      placeholder={`${t(
                                        "address.placeholder"
                                      )}`}
                                      // rows={2}
                                      maxLength={30}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="address"
                                      className="text-danger"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={12} lg={12} xl={6}>
                                  <Form.Group
                                    controlId="formBasicEmail"
                                    className="mb-3"
                                  >
                                    <Form.Label>
                                      {t("zip_code.name")}
                                      <span>*</span>
                                    </Form.Label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name="postal_code"
                                      placeholder={t("zip_code.placeholder")}
                                      maxLength={20}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="postal_code"
                                      className="text-danger"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={12} lg={12} xl={6}>
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>
                                      {t("country.name")} <span>*</span>
                                    </Form.Label>
                                    <Select
                                      options={countryList}
                                      value={country}
                                      styles={customStyles}
                                      onChange={(selectedOption) => {
                                        setCountry(selectedOption);
                                        setFieldValue(
                                          "country",
                                          selectedOption.value
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="country"
                                      className="text-danger text-right"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={12} lg={12} xl={12}>
                                  <Form.Group
                                    controlId="formBasicEmail"
                                    className="mb-3"
                                  >
                                    <Form.Label>
                                      {t("source_of_funds.name")}
                                    </Form.Label>
                                    <Field
                                      className="form-control"
                                      name="source_of_funds"
                                      placeholder={`${t(
                                        "source_of_funds.placeholder"
                                      )}`}
                                      // rows={2}
                                      maxLength={30}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="source_of_funds"
                                      className="text-danger"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col md={12} lg={12} className="mt-3">
                            <div className="create-beneficiary-btn-sec">
                              <Button
                                className="efi-overlay-btn"
                                onClick={() => navigate(-1)}
                              >
                                {t("cancel")}
                              </Button>
                              <Button
                                className="efi-primary-btn"
                                type="submit"
                                disabled={senderUpdate.buttonDisable}
                              >
                                {senderUpdate.buttonDisable ? (
                                  <ButtonLoader />
                                ) : (
                                  "Update"
                                )}
                              </Button>
                            </div>
                          </Col>
                        </React.Fragment>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </FORM>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default EditSender;
