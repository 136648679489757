import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Form, Dropdown } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import ViewTransactionModal from "./ViewTransactionHistoryModal";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { DateObject } from "react-multi-date-picker";
import Footer from "react-multi-date-picker/plugins/range_picker_footer";
import { useDispatch, useSelector } from "react-redux";
import "./payment.css";
import { transactionListStart } from "../../store/slices/PaymentSlice";
import { Field, Form as FORM, Formik } from "formik";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import * as Yup from "yup";
import Select from "react-select";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../Helper/NotificationMessage";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import Pagination from "../Helper/Pagination";
import { accountsStatementsStart } from "../../store/slices/AccountsSlice";
import { ButtonLoader, ButtonLoaderDark } from "../Helper/Loader";
import CopyToClipboard from "react-copy-to-clipboard";
import InputIcon from "react-multi-date-picker/components/input_icon";
import NoDataFoundNew from "../Helper/NoDataFoundNew";

const TransactionHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [searchParams] = useSearchParams();
  const t = useTranslation("transactions");

  const transactionList = useSelector((state) => state.payment.transactionList);
  const profile = useSelector((state) => state.admin.profile);
  const accountsStatements = useSelector((state) => state.accounts.accountsStatements);

  const [viewTransactionHistory, setViewTransactionHistory] = useState(false);
  const [startDate, setStartDate] = useState(new Date("2024/04/08"));
  const [endDate, setEndDate] = useState(new Date("2024/02/10"));
  const [skipRender, setSkipRender] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [typeStatus, setTypeStatus] = useState(false);
  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    status: "",
    type: "",
  });
  const [close, setClose] = useState(false);
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const calenderRefs = useRef(null);
  const [dateValues, setDateValues] = useState({
    from_date: "",
    to_date: "",
  });
  const [value, setValue] = useState([null, null]);

  const options = [
    { value: "1", label: "Initiated" },
    { value: "2", label: "Inprogress " },
    { value: "3", label: "Completed" },
    { value: "4", label: "Failed" },
  ];

  const type_options = [
    { value: "1", label: t("credit") },
    { value: "2", label: t("debit") },
  ];

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(
        transactionListStart({
          ...filter,
          skip: 12 * (page - 1),
          take: 12,
        })
      );
    }
  }, [profile, filter, page]);

  const handleExport = () => {
    dispatch(
      accountsStatementsStart({
        ...filter,
        from_date: dateValues.from_date
          ?.toString()
          .split("-")
          .reverse()
          .join("-"),
        to_date: dateValues.to_date?.toString().split("-").reverse().join("-"),
      })
    );
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return "payment-initiated";
      case 2:
        return "payment-completed";
      case 3:
        return "payment-failed";
      default:
        return "payment-initiated";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 1:
        return "#5C9EEB";
      case 2:
        return "#197E23";
      case 3:
        return "#FF6A6A";
      default:
        return "#5C9EEB";
    }
  };

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    navigate(`/transactions?${params}page=${selected + 1}`);
  };

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
      setFilter({
        ...filter,
        search_key: searchParams.get("search_key")
          ? searchParams.get("search_key")
          : "",
        from_date: dateValues.from_date,
        to_date: dateValues.to_date,
      });
    }
    setSkipRender(false);
  }, [searchParams.get("page"), searchParams.get("search_key")]);

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
    }
    setSkipRender(false);
  }, [searchParams.get("page")]);

  useEffect(() => {
    if (
      !skipRender &&
      !accountsStatements.buttonDisable &&
      Object.keys(accountsStatements.data).length > 0
    ) {
      accountsStatements.data?.url &&
        window.open(accountsStatements.data.url, "_blank");
    }
    setSkipRender(false);
  }, [accountsStatements]);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E5E8EF!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "180px",
      cursor: "pointer",
      fontSize: "0.95em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.95em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#1b3d86",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.95em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#1b3d86!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#878E96",
      },
    }),
    //   option: (provided, state) => ({
    //     ...provided,
    //     backgroundColor: state.isFocused || state.isSelected ? '#1b3d86' : '#fff',
    //     color: state.isFocused || state.isSelected ? '#fff' : '#000',
    //     ":hover": {
    //             backgroundColor: "#1b3d86",
    //             color: "#fff"
    //           },
    // }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#1b3d86"
        : state.isFocused
        ? "#f0f0f0"
        : "transparent",
      color: state.isSelected ? "#fff" : state.isFocused ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#f0f0f0",
        color: "#000",
      },
    }),
  };

  const validationSchema = Yup.object().shape({
    search_key: Yup.string()
      .required(t("required"))
      .matches(/^(?=\S*$)/, t("white_space")),
  });

  const handleDateChange = (values) => {
    if (values) {
      setValue(values);
      formRef?.current?.setFieldValue(
        "from_date",
        values && values.length > 0
          ? JSON.stringify(new Date(values[0])).slice(1, 11)
          : ""
      );
      formRef?.current?.setFieldValue(
        "to_date",
        values && values.length > 1
          ? JSON.stringify(new Date(values[1])).slice(1, 11)
          : ""
      );
      setDateValues({
        from_date:
          values.length > 0
            ? JSON.stringify(new Date(values[0])).slice(1, 11)
            : "",
        to_date:
          values.length > 1
            ? JSON.stringify(new Date(values[1])).slice(1, 11)
            : "",
      });
    } else {
      setDateValues({
        from_date: "",
        to_date: "",
      });
      formRef?.current?.setFieldValue("from_date", "");
      formRef?.current?.setFieldValue("to_date", "");
      calenderRefs.current.closeCalendar();
      setFilter({
        ...filter,
        skip: 12 * (page - 1),
        take: 12,
        from_date: "",
        to_date: "",
      });
    }
  };

  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="common-full-card">
          <div className="efi-feature-header-frame layout-between">
            <div className="efi-feature-transation-title">
              <h2>
                {t("heading")} &nbsp;
                <span className="ml-3">
                  {transactionList.data?.total_m_transactions || 0}
                </span>
              </h2>
            </div>
            <div className="efi-feature-transation-search-frame">
              <Select
                options={type_options}
                className="select-payout"
                styles={customStyles}
                isSearchable={false}
                isClearable
                value={typeStatus || null}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setFilter({
                      ...filter,
                      type: selectedOption.value,
                    });
                    setTypeStatus(selectedOption);
                    navigate(
                      filter.search_key
                        ? `/transactions?search_key=${filter.search_key}&type=${selectedOption.value}`
                        : `/transactions?type=${selectedOption.value}`
                    );
                  } else {
                    const newFilter = { ...filter };
                    delete newFilter.type;
                    setFilter(newFilter);
                    navigate(
                      filter.search_key
                        ? `/transactions?search_key=${filter.search_key}`
                        : "/transactions"
                    );
                    setTypeStatus(null);
                  }
                }}
                placeholder={
                  <div className="placeholder-flex">{t("type")}</div>
                }
              />
              <div className="header-search">
                <Formik
                  initialValues={{
                    search_key: searchParams.get("search_key")
                      ? searchParams.get("search_key")
                      : "",
                    sort_by: searchParams.get("sort_by")
                      ? searchParams.get("sort_by")
                      : filter.sort_by,
                    from_date: "",
                    to_date: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(val) => {
                    setFilter({
                      ...filter,
                      search_key: val.search_key,
                      from_date: dateValues.from_date,
                      to_date: dateValues.to_date,
                    });

                    navigate(
                      searchParams.get("sort_by")
                        ? `/transactions?search_key=${
                            val.search_key
                          }&sort_by=${searchParams.get("sort_by")}`
                        : `/transactions?search_key=${val.search_key}`,
                      { state: { value: value } }
                    );
                  }}
                  innerRef={formRef}
                >
                  {({ values, setFieldValue, resetForm }) => (
                    <FORM className="form">
                      <InputGroup
                        className={`d-flex date-sec mb-0 efi-data-input ${
                          close ? "date-close" : ""
                        }`}
                      >
                        <DatePicker
                          value={value}
                          style={{ padding: "1em" }}
                          onChange={(date) => {
                            handleDateChange(date);
                          }}
                          range
                          numberOfMonths={2}
                          ref={calenderRefs}
                          format="DD-MM-YYYY"
                          maxDate={
                            new Date(new Date().setHours(23, 59, 59, 999))
                          }
                          id="date-picker-input"
                          placeholder={t("date_range")}
                          // render={<InputIcon/>}
                        >
                          <div
                            className="d-flex justify-content-end gap-2"
                            style={{ padding: "1em" }}
                          >
                            <Button
                              className="efi-datePicker-btn-cancel"
                              onClick={() => {
                                calenderRefs.current.closeCalendar();
                              }}
                            >
                              {t("close")}
                            </Button>
                            <Button
                              className="efi-datePicker-btn"
                              onClick={() => {
                                if (values.from_date && values.to_date) {
                                  setFilter({
                                    ...filter,
                                    skip: 12 * (page - 1),
                                    take: 12,
                                    from_date: dateValues.from_date
                                      .toString()
                                      .split("-")
                                      .reverse()
                                      .join("-"),
                                    to_date: dateValues.to_date
                                      .toString()
                                      .split("-")
                                      .reverse()
                                      .join("-"),
                                  });
                                  calenderRefs.current.closeCalendar();
                                  setClose(true);
                                } else
                                  getErrorNotificationMessage(
                                    "Please select date"
                                  );
                              }}
                            >
                              {t("apply")}
                            </Button>
                          </div>
                        </DatePicker>
                        {close ? (
                          <>
                            <button
                              type="button"
                              className="search-btn date-cancel"
                              onClick={() => {
                                // setValue([null , new Date()]);
                                setDateValues({
                                  from_date: "",
                                  to_date: "",
                                });
                                setValue([null, null]);
                                setClose(false);
                                setFilter({
                                  ...filter,
                                  from_date: "",
                                  to_date: "",
                                });
                              }}
                            >
                              <svg
                                height="24"
                                width="24"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                                focusable="false"
                                class="css-tj5bde-Svg"
                              >
                                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                              </svg>
                            </button>
                          </>
                        ) : (
                          <InputGroup.Text
                            onClick={() => {
                              const input =
                                document.getElementById("date-picker-input");
                              if (input) {
                                input.focus();
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              fill="none"
                              viewBox="0 0 18 18"
                            >
                              <g clipPath="url(#clip0_47_6)">
                                <path
                                  fill="#8F9899"
                                  d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0_47_6">
                                  <path fill="#fff" d="M0 0H18V18H0z"></path>
                                </clipPath>
                              </defs>
                            </svg>
                          </InputGroup.Text>
                        )}
                      </InputGroup>
                      <div className="efi-transation-search">
                        <InputGroup className="mb-0">
                          <Field
                            // placeholder={t("search_placeholder")}
                            placeholder={t("search")}
                            type="text"
                            className="form-control trans-form-control"
                            name="search_key"
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                formRef.current.handleSubmit();
                              }
                            }}
                            disabled={
                              !transactionList.data?.m_transactions?.length > 0
                            }
                          />
                          {searchParams.get("search_key") ? (
                            <InputGroup.Text id="basic-addon1">
                              <Button
                                className="search-btn"
                                onClick={() => {
                                  setFieldValue("search_key", "");
                                  setFilter({
                                    ...filter,
                                    search_key: "",
                                    from_date: dateValues.from_date,
                                    to_date: dateValues.to_date,
                                  });
                                  navigate(
                                    searchParams.get("sort_by")
                                      ? `/transactions?sort_by=${searchParams.get(
                                          "sort_by"
                                        )}`
                                      : `/transactions`
                                  );
                                }}
                              >
                                <svg
                                  height="20"
                                  width="20"
                                  viewBox="0 0 20 20"
                                  aria-hidden="true"
                                  focusable="false"
                                  class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                                >
                                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                </svg>
                              </Button>
                            </InputGroup.Text>
                          ) : (
                            <InputGroup.Text id="basic-addon1">
                              <Button className="search-btn" type="submit">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#333"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  // class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                                  <path d="M21 21l-6 -6" />
                                </svg>
                              </Button>
                            </InputGroup.Text>
                          )}
                        </InputGroup>
                        <Dropdown className="download-dropdown">
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            disabled={
                              transactionList.buttonDisable ||
                              accountsStatements.buttonDisable ||
                              !(transactionList.data.m_transactions?.length > 0)
                            }
                          >
                            {accountsStatements.buttonDisable ? (
                              <ButtonLoaderDark />
                            ) : (
                              <svg
                                style={{ display: "flex", margin: "auto" }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-download"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                                <path d="M7 11l5 5l5 -5" />
                                <path d="M12 4l0 12" />
                              </svg>
                            )}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                dispatch(
                                  accountsStatementsStart({
                                    ...filter,
                                    export_type: 1,
                                  })
                                )
                              }
                            >
                              {t("pdf")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                dispatch(
                                  accountsStatementsStart({
                                    ...filter,
                                    export_type: 2,
                                  })
                                )
                              }
                            >
                              {t("excel")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </FORM>
                  )}
                </Formik>
              </div>
              {/* <div className="efi-transation-action"> */}
              {/* <Button
                      className="download-btn"
                      onClick={handleExport}
                      disabled={accountsStatements.buttonDisable || !transactionList.data.m_transactions.length > 0}
                    >
                      {accountsStatements.buttonDisable ? (
                        <ButtonLoaderDark />
                      ) : (
                        <svg
                          style={{ display: "flex", margin: "auto" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-download"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                          <path d="M7 11l5 5l5 -5" />
                          <path d="M12 4l0 12" />
                        </svg>
                      )}
                    </Button> */}
              {/* </div> */}
            </div>
          </div>
          <div>
            {transactionList.loading ? (
              <>
                {[...Array(6)].map((_, key) => (
                  <Skeleton key={key} className="mb-3" height={80} width={"100%"} />
                ))}
              </>
            ) : (Object.keys(transactionList.data).length > 0 &&
              transactionList.data.m_transactions?.length > 0) ? (
              <Table className="common-table" responsive>
                <thead className="common-table-header">
                  <tr>
                    <th>{t("date")}</th>
                    <th>{t("transaction_id")}</th>
                    <th>{t("recipient_name")}</th>
                    <th>{t("email")}</th>
                    <th>{t("amount")}</th>
                    <th>{t("transaction_remarks")}</th>
                    <th>{t("status")}</th>
                  </tr>
                </thead>
                <tbody className="common-table-body">
                  {transactionList.data.m_transactions.map((transaction, i) => (
                    <tr key={i}>
                      <td>{transaction.created_at_formatted}</td>
                      <td>
                        {transaction.transaction_id ? (
                          <>
                            {transaction.transaction_id.slice(0, 3) +
                              "..." +
                              transaction.transaction_id.slice(-3)}{" "}
                            <CopyToClipboard text={transaction.transaction_id}>
                              <Button
                                className="copy-btn"
                                onClick={() =>
                                  getSuccessNotificationMessage("Copied!")
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#1b3d86"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                  <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                </svg>
                              </Button>
                            </CopyToClipboard>
                          </>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>{transaction.name}</td>
                      <td>{transaction.email || "N/A"}</td>
                      <td>
                        {transaction.type == 1 ? (
                          <span className="color-green">
                            + ${transaction.amount}
                          </span>
                        ) : (
                          <span className="color-red">
                            - ${transaction.amount}
                          </span>
                        )}
                      </td>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <span className="efi-trade-card">
                            {transaction.type == 1 ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                enableBackground="new 0 0 512 512"
                                viewBox="0 0 28 28"
                              >
                                <switch>
                                  <g>
                                    <path
                                      fill="#30a75f"
                                      d="M24.7 4.7L6.4 23H20c.6 0 1 .4 1 1s-.4 1-1 1H4c-.6 0-1-.4-1-1V8c0-.6.4-1 1-1s1 .4 1 1v13.6L23.3 3.3c.4-.4 1-.4 1.4 0s.4 1 0 1.4z"
                                      data-original="#88428d"
                                    ></path>
                                  </g>
                                </switch>
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                enableBackground="new 0 0 512 512"
                                viewBox="0 0 28 28"
                                className="rote"
                              >
                                <switch>
                                  <g>
                                    <path
                                      fill="#EB6363"
                                      d="M24.7 4.7L6.4 23H20c.6 0 1 .4 1 1s-.4 1-1 1H4c-.6 0-1-.4-1-1V8c0-.6.4-1 1-1s1 .4 1 1v13.6L23.3 3.3c.4-.4 1-.4 1.4 0s.4 1 0 1.4z"
                                      data-original="#88428d"
                                    ></path>
                                  </g>
                                </switch>
                              </svg>
                            )}
                          </span>
                          <p className="mb-0 text-fit">
                            {" "}
                            {transaction.description}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div
                          className={getStatusClass(transaction.status)}
                          // className="badges success"
                        >
                          {transaction.status_formatted}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <NoDataFoundNew />
            )}
            {transactionList.data.total_m_transactions > 12 ? (
              <div className="new-billings-pagination-sec">
                <Pagination
                  page={page}
                  pageCount={Math.ceil(
                    transactionList.data.total_m_transactions / 12
                  )}
                  handlePageClick={handlePageClick}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <ViewTransactionModal
        show={viewTransactionHistory}
        onHide={() => setViewTransactionHistory(false)}
      />
    </>
  );
};

export default TransactionHistory;
